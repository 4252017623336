<template>
  <div class="Client font">
    <!-- BEGIN: Content-->
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>

      <div class="content-wrapper container-xxl p-0 font">
        <banner
          title="Permissions"
          :breadcrumb="[
         
            {
                label: 'Dashboard',
            },
            {
              label: 'Masters',
            },
            { label: 'Permissions' }
          ]"
        >
        </banner>
        <div v-if="loading == true">
          <Spinner />
        </div>

        <div class="row font" style="margin-top: -45px">
          <div class="col-xl-12 col-md-12 col-sm-12">
            <div class="d-xl-none d-md-none d-block pt-1"></div>
            <div
              class="card custom-card"
              style=" height: 100%"
            >
              <div class="card-body">
                <!-- start list area  -->

                <div class="row">
                  <div class="col-9">
                    <h2 class="heading" data-v-1b249e63="" style="color: #00364f">
                      <strong>Staff Permissions</strong>
                    </h2>
                  </div>
                  <div class="col-3">
                    <select
                      @change="departmentDetailsAssin($event)"
                      class="form-select"
                      aria-label="Default select example"
                    >
                      <option selected disabled>Select Department</option>
                      <option
                        :value="depart.id"
                        v-for="(depart, index) in departments"
                        :key="index"
                      >
                        {{ depart.name }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-12" data-v-1b249e63="">
                    <div
                      class="accordion accordion-margin accordion-border"
                      id="accordionMargin"
                      data-v-1b249e63=""
                    >
                      <div class="accordion-item" data-v-1b249e63="">
                        <h2
                          @click="adminpermission()"
                          class="accordion-header"
                          id="headingMarginOne"
                          data-v-1b249e63=""
                        >
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#accordionMarginOne"
                            aria-expanded="false"
                            aria-controls="accordionMarginOne"
                            data-v-1b249e63=""
                          >
                            <i
                              data-feather="plus"
                              class="accordian-plus"
                              data-v-1b249e63=""
                            ></i>
                            Admin
                          </button>
                        </h2>
                        <div
                          id="accordionMarginOne"
                          class="accordion-collapse collapse"
                          aria-labelledby="headingMarginOne"
                          data-bs-parent="#accordionMargin"
                          data-v-1b249e63=""
                          style=""
                        >
                          <div class="accordion-body" data-v-1b249e63="">
                            <div class="row">
                              <div
                                class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                style="margin-top: 7px"
                              >
                                <div class="container-fluid table-scroll">
                                  <table class="table table-hover table-sm">
                                    <tr
                                      class="text tr-head table-wrapper-scroll-y rounded-circle"
                                    >
                                      <th>+</th>

                                      <th>Name</th>

                                      <th>For</th>
                                      <th>Description</th>
                                      <th>Status</th>
                                      <th class="text-end">
                                        <span v-if="department_id != null">
                                          <input
                                            v-if="
                                              prePermissionIDs.length ==
                                              permissions.length
                                            "
                                            @click="
                                              allCheclSand($event, permissions, 'admin')
                                            "
                                            type="checkbox"
                                            checked
                                          />
                                          <input
                                            v-else
                                            @click="allCheclSand($event, permissions)"
                                            type="checkbox"
                                          />
                                        </span>
                                        <span v-else>
                                          <input type="checkbox" checked disabled />
                                        </span>
                                      </th>
                                    </tr>

                                    <tbody>
                                      <tr
                                        style="color: #00364f"
                                        v-for="(permission, index) in permissions"
                                        :key="index"
                                      >
                                        <td>
                                          {{ index + 1 }}
                                        </td>

                                        <td class="text-truncate">
                                          {{ permission.permission }}
                                        </td>
                                        <td>{{ permission.for }}</td>
                                        <td>{{ permission.description }}</td>
                                        <td>{{ permission.sts }}</td>

                                        <td class="text-end">
                                          <input
                                            v-if="permission.sts == 'true'"
                                            @click="
                                              singleCheckedUncheked(
                                                $event,
                                                permission,
                                                permissions,
                                                'admin'
                                              )
                                            "
                                            type="checkbox"
                                            checked
                                          />
                                          <input
                                            v-if="permission.sts == 'false'"
                                            @click="
                                              singleCheckedUncheked(
                                                $event,
                                                permission,
                                                permissions,
                                                'admin'
                                              )
                                            "
                                            type="checkbox"
                                          />

                                          <span v-if="!permission.sts">
                                            <input disabled type="checkbox" />
                                          </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item" data-v-1b249e63="">
                        <h2
                          @click="adminkycpermission()"
                          class="accordion-header"
                          id="headingMarginTwo"
                          data-v-1b249e63=""
                        >
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#admin-kyc"
                            aria-expanded="false"
                            aria-controls="admin-kyc"
                            data-v-1b249e63=""
                          >
                            <i
                              data-feather="plus"
                              class="accordian-plus"
                              data-v-1b249e63=""
                            ></i>
                            Admin Kyc
                          </button>
                        </h2>
                        <div
                          id="admin-kyc"
                          class="accordion-collapse collapse"
                          aria-labelledby="admin-kyc"
                          data-bs-parent="#admin-kyc"
                          data-v-1b249e63=""
                          style=""
                        >
                          <div class="accordion-body" data-v-1b249e63="">
                            <div class="row">
                              <div
                                class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                style="margin-top: 7px"
                              >
                                <div class="container-fluid table-scroll">
                                  <table class="table table-hover table-sm">
                                    <tr
                                      class="text tr-head table-wrapper-scroll-y rounded-circle"
                                    >
                                      <th>+</th>

                                      <th>Name</th>

                                      <th>For</th>
                                      <th>Description</th>
                                      <th>Status</th>
                                      <th class="text-end">
                                        <span v-if="department_id != null">
                                          <input
                                            v-if="
                                              prePermissionIDs1.length ==
                                              permissions1.length
                                            "
                                            @click="
                                              allCheclSand(
                                                $event,
                                                permissions1,
                                                'admin-kyc'
                                              )
                                            "
                                            type="checkbox"
                                            checked
                                          />
                                          <input
                                            v-else
                                            @click="
                                              allCheclSand(
                                                $event,
                                                permissions1,
                                                'admin-kyc'
                                              )
                                            "
                                            type="checkbox"
                                          />
                                        </span>
                                        <span v-else>
                                          <input type="checkbox" checked disabled />
                                        </span>
                                      </th>
                                    </tr>

                                    <tbody>
                                      <tr
                                        style="color: #00364f"
                                        v-for="(permission1, index) in permissions1"
                                        :key="index"
                                      >
                                        <td>
                                          {{ index + 1 }}
                                        </td>

                                        <td class="text-truncate">
                                          {{ permission1.permission }}
                                        </td>
                                        <td>{{ permission1.for }}</td>

                                        <td>{{ permission1.description }}</td>
                                        <td>{{ permission1.sts }}</td>

                                        <td class="text-end">
                                          <input
                                            v-if="permission1.sts == 'true'"
                                            @click="
                                              singleCheckedUncheked(
                                                $event,
                                                permission1,
                                                permissions1,
                                                'admin-kyc'
                                              )
                                            "
                                            type="checkbox"
                                            checked
                                          />
                                          <input
                                            v-if="permission1.sts == 'false'"
                                            @click="
                                              singleCheckedUncheked(
                                                $event,
                                                permission1,
                                                permissions1,
                                                'admin-kyc'
                                              )
                                            "
                                            type="checkbox"
                                          />

                                          <span v-if="!permission1.sts">
                                            <input disabled type="checkbox" />
                                          </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item" data-v-1b249e63="">
                        <h2
                          @click="clientpermission()"
                          class="accordion-header"
                          data-v-1b249e63=""
                        >
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#client"
                            aria-expanded="false"
                            aria-controls="client"
                            data-v-1b249e63=""
                          >
                            <i
                              data-feather="plus"
                              class="accordian-plus"
                              data-v-1b249e63=""
                            ></i>
                            Client
                          </button>
                        </h2>
                        <div
                          id="client"
                          class="accordion-collapse collapse"
                          aria-labelledby="client"
                          data-bs-parent="#client"
                          data-v-1b249e63=""
                        >
                          <div class="accordion-body" data-v-1b249e63="">
                            <div class="row">
                              <div
                                class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                style="margin-top: 7px"
                              >
                                <div class="container-fluid table-scroll">
                                  <table class="table table-hover table-sm">
                                    <tr
                                      class="text tr-head table-wrapper-scroll-y rounded-circle"
                                    >
                                      <th>+</th>

                                      <th>Name</th>

                                      <th>For</th>
                                      <th>Description</th>
                                      <th>Status</th>
                                      <th class="text-end">
                                        <input
                                          v-if="
                                            prePermissionIDs2.length ==
                                            permissions2.length
                                          "
                                          @click="
                                            allCheclSand($event, permissions2, 'client')
                                          "
                                          type="checkbox"
                                          checked
                                        />
                                        <input
                                          v-else
                                          @click="
                                            allCheclSand($event, permissions2, 'client')
                                          "
                                          type="checkbox"
                                        />
                                      </th>
                                    </tr>

                                    <tbody>
                                      <tr
                                        style="color: #00364f"
                                        v-for="(permission2, index) in permissions2"
                                        :key="index"
                                      >
                                        <td>
                                          {{ index + 1 }}
                                        </td>

                                        <td class="text-truncate">
                                          {{ permission2.permission }}
                                        </td>
                                        <td>{{ permission2.for }}</td>
                                        <td>{{ permission2.description }}</td>
                                        <td>{{ permission2.sts }}</td>

                                        <td class="text-end">
                                          <input
                                            v-if="permission2.sts == 'true'"
                                            @click="
                                              singleCheckedUncheked(
                                                $event,
                                                permission2,
                                                permissions2,
                                                'client'
                                              )
                                            "
                                            type="checkbox"
                                            checked
                                          />
                                          <input
                                            v-if="permission2.sts == 'false'"
                                            @click="
                                              singleCheckedUncheked(
                                                $event,
                                                permission2,
                                                permissions2,
                                                'client'
                                              )
                                            "
                                            type="checkbox"
                                          />

                                          <span v-if="!permission2.sts">
                                            <input disabled type="checkbox" />
                                          </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item" data-v-1b249e63="">
                        <h2
                          class="accordion-header"
                          @click="clientkycpermission()"
                          data-v-1b249e63=""
                        >
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#client-kyc"
                            aria-expanded="false"
                            aria-controls="client-kyc"
                            data-v-1b249e63=""
                          >
                            <i
                              data-feather="plus"
                              class="accordian-plus"
                              data-v-1b249e63=""
                            ></i>
                            Client Kyc
                          </button>
                        </h2>
                        <div
                          id="client-kyc"
                          class="accordion-collapse collapse"
                          aria-labelledby="client-kyc"
                          data-bs-parent="#accordionMargin"
                          data-v-1b249e63=""
                        >
                          <div class="accordion-body" data-v-1b249e63="">
                            <div class="row">
                              <div
                                class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                style="margin-top: 7px"
                              >
                                <div class="container-fluid table-scroll">
                                  <table class="table table-hover table-sm">
                                    <tr
                                      class="text tr-head table-wrapper-scroll-y rounded-circle"
                                    >
                                      <th>+</th>

                                      <th>Name</th>

                                      <th>For</th>
                                      <th>Description</th>
                                      <th>Status</th>
                                      <th class="text-end">
                                        <input
                                          v-if="
                                            prePermissionIDs3.length ==
                                            permissions3.length
                                          "
                                          @click="
                                            allCheclSand(
                                              $event,
                                              permissions3,
                                              'client-kyc'
                                            )
                                          "
                                          type="checkbox"
                                          checked
                                        />
                                        <input
                                          v-else
                                          @click="
                                            allCheclSand(
                                              $event,
                                              permissions3,
                                              'client-kyc'
                                            )
                                          "
                                          type="checkbox"
                                        />
                                      </th>
                                    </tr>

                                    <tbody>
                                      <tr
                                        style="color: #00364f"
                                        v-for="(permission3, index) in permissions3"
                                        :key="index"
                                      >
                                        <td>
                                          {{ index + 1 }}
                                        </td>

                                        <td class="text-truncate">
                                          {{ permission3.permission }}
                                        </td>
                                        <td>{{ permission3.for }}</td>

                                        <td>{{ permission3.description }}</td>

                                        <td>{{ permission3.sts }}</td>

                                        <td class="text-end">
                                          <input
                                            v-if="permission3.sts == 'true'"
                                            @click="
                                              singleCheckedUncheked(
                                                $event,
                                                permission3,
                                                permissions3,
                                                'client-kyc'
                                              )
                                            "
                                            type="checkbox"
                                            checked
                                          />
                                          <input
                                            v-if="permission3.sts == 'false'"
                                            @click="
                                              singleCheckedUncheked(
                                                $event,
                                                permission3,
                                                permissions3,
                                                'client-kyc'
                                              )
                                            "
                                            type="checkbox"
                                          />

                                          <span v-if="!permission3.sts">
                                            <input disabled type="checkbox" />
                                          </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item" data-v-1b249e63="">
                        <h2
                          class="accordion-header"
                          @click="distributerpermission()"
                          data-v-1b249e63=""
                        >
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#distributer"
                            aria-expanded="false"
                            aria-controls="distributer"
                            data-v-1b249e63=""
                          >
                            <i
                              data-feather="plus"
                              class="accordian-plus"
                              data-v-1b249e63=""
                            ></i>
                            Distributer
                          </button>
                        </h2>
                        <div
                          id="distributer"
                          class="accordion-collapse collapse"
                          aria-labelledby="distributer"
                          data-bs-parent="#accordionMargin"
                          data-v-1b249e63=""
                        >
                          <div class="accordion-body" data-v-1b249e63="">
                            <div class="row">
                              <div
                                class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                style="margin-top: 7px"
                              >
                                <div class="container-fluid table-scroll">
                                  <table class="table table-hover table-sm">
                                    <tr
                                      class="text tr-head table-wrapper-scroll-y rounded-circle"
                                    >
                                      <th>+</th>

                                      <th>Name</th>

                                      <th>For</th>
                                      <th>Description</th>
                                      <th>Status</th>
                                      <th class="text-end">
                                        <input
                                          v-if="
                                            prePermissionIDs4.length ==
                                            permissions4.length
                                          "
                                          @click="
                                            allCheclSand(
                                              $event,
                                              permissions4,
                                              'distributer'
                                            )
                                          "
                                          type="checkbox"
                                          checked
                                        />
                                        <input
                                          v-else
                                          @click="
                                            allCheclSand(
                                              $event,
                                              permissions4,
                                              'distributer'
                                            )
                                          "
                                          type="checkbox"
                                        />
                                      </th>
                                    </tr>

                                    <tbody>
                                      <tr
                                        style="color: #00364f"
                                        v-for="(permission4, index) in permissions4"
                                        :key="index"
                                      >
                                        <td>
                                          {{ index + 1 }}
                                        </td>

                                        <td class="text-truncate">
                                          {{ permission4.permission }}
                                        </td>
                                        <td>{{ permission4.for }}</td>
                                        <td>{{ permission4.description }}</td>
                                        <td>{{ permission4.sts }}</td>

                                        <td class="text-end">
                                          <input
                                            v-if="permission4.sts == 'true'"
                                            @click="
                                              singleCheckedUncheked(
                                                $event,
                                                permission4,
                                                permissions4,
                                                'distributer'
                                              )
                                            "
                                            type="checkbox"
                                            checked
                                          />
                                          <input
                                            v-if="permission4.sts == 'false'"
                                            @click="
                                              singleCheckedUncheked(
                                                $event,
                                                permission4,
                                                permissions4,
                                                'distributer'
                                              )
                                            "
                                            type="checkbox"
                                          />

                                          <span v-if="!permission4.sts">
                                            <input disabled type="checkbox" />
                                          </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item" data-v-1b249e63="">
                        <h2
                          class="accordion-header"
                          @click="distributerkycpermission()"
                          data-v-1b249e63=""
                        >
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#distributer-kyc"
                            aria-expanded="false"
                            aria-controls="distributer-kyc"
                            data-v-1b249e63=""
                          >
                            <i
                              data-feather="plus"
                              class="accordian-plus"
                              data-v-1b249e63=""
                            ></i>
                            Distributer Kyc
                          </button>
                        </h2>
                        <div
                          id="distributer-kyc"
                          class="accordion-collapse collapse"
                          aria-labelledby="distributer-kyc"
                          data-bs-parent="#accordionMargin"
                          data-v-1b249e63=""
                        >
                          <div class="accordion-body" data-v-1b249e63="">
                            <div class="row">
                              <div
                                class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                style="margin-top: 7px"
                              >
                                <div class="container-fluid table-scroll">
                                  <table class="table table-hover table-sm">
                                    <tr
                                      class="text tr-head table-wrapper-scroll-y rounded-circle"
                                    >
                                      <th>+</th>

                                      <th>Name</th>

                                      <th>For</th>
                                      <th>Description</th>
                                      <th>Status</th>
                                      <th class="text-end">
                                        <input
                                          v-if="
                                            prePermissionIDs5.length ==
                                            permissions5.length
                                          "
                                          @click="
                                            allCheclSand(
                                              $event,
                                              permissions5,
                                              'distributer-kyc'
                                            )
                                          "
                                          type="checkbox"
                                          checked
                                        />
                                        <input
                                          v-else
                                          @click="
                                            allCheclSand(
                                              $event,
                                              permissions5,
                                              'distributer-kyc'
                                            )
                                          "
                                          type="checkbox"
                                        />
                                      </th>
                                    </tr>

                                    <tbody>
                                      <tr
                                        style="color: #00364f"
                                        v-for="(permission5, index) in permissions5"
                                        :key="index"
                                      >
                                        <td>
                                          {{ index + 1 }}
                                        </td>

                                        <td class="text-truncate">
                                          {{ permission5.permission }}
                                        </td>
                                        <td>{{ permission5.for }}</td>
                                        <td>{{ permission5.description }}</td>
                                        <td>{{ permission5.sts }}</td>

                                        <td class="text-end">
                                          <input
                                            v-if="permission5.sts == 'true'"
                                            @click="
                                              singleCheckedUncheked(
                                                $event,
                                                permission5,
                                                permissions5,
                                                'distributer-kyc'
                                              )
                                            "
                                            type="checkbox"
                                            checked
                                          />
                                          <input
                                            v-if="permission5.sts == 'false'"
                                            @click="
                                              singleCheckedUncheked(
                                                $event,
                                                permission5,
                                                permissions5,
                                                'distributer-kyc'
                                              )
                                            "
                                            type="checkbox"
                                          />

                                          <span v-if="!permission5.sts">
                                            <input disabled type="checkbox" />
                                          </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item" data-v-1b249e63="">
                        <h2
                          @click="distributerwalletpermission()"
                          class="accordion-header"
                          data-v-1b249e63=""
                        >
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#distributer-wallet"
                            aria-expanded="false"
                            aria-controls="distributer-wallet"
                            data-v-1b249e63=""
                          >
                            <i
                              data-feather="plus"
                              class="accordian-plus"
                              data-v-1b249e63=""
                            ></i>
                            Distributer Wallet
                          </button>
                        </h2>
                        <div
                          id="distributer-wallet"
                          class="accordion-collapse collapse"
                          aria-labelledby="distributer-wallet"
                          data-bs-parent="#accordionMargin"
                          data-v-1b249e63=""
                        >
                          <div class="accordion-body" data-v-1b249e63="">
                            <div class="row">
                              <div
                                class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                style="margin-top: 7px"
                              >
                                <div class="container-fluid table-scroll">
                                  <table class="table table-hover table-sm">
                                    <tr
                                      class="text tr-head table-wrapper-scroll-y rounded-circle"
                                    >
                                      <th>+</th>

                                      <th>Name</th>

                                      <th>For</th>
                                      <th>Description</th>
                                      <th>Status</th>
                                      <th class="text-end">
                                        <input
                                          v-if="
                                            prePermissionIDs6.length ==
                                            permissions6.length
                                          "
                                          @click="
                                            allCheclSand(
                                              $event,
                                              permissions6,
                                              'distributer-wallet'
                                            )
                                          "
                                          type="checkbox"
                                          checked
                                        />
                                        <input
                                          v-else
                                          @click="
                                            allCheclSand(
                                              $event,
                                              permissions6,
                                              'distributer-wallet'
                                            )
                                          "
                                          type="checkbox"
                                        />
                                      </th>
                                    </tr>

                                    <tbody>
                                      <tr
                                        style="color: #00364f"
                                        v-for="(permission6, index) in permissions6"
                                        :key="index"
                                      >
                                        <td>
                                          {{ index + 1 }}
                                        </td>

                                        <td class="text-truncate">
                                          {{ permission6.permission }}
                                        </td>
                                        <td>{{ permission6.for }}</td>
                                        <td>{{ permission6.description }}</td>
                                        <td>{{ permission6.sts }}</td>

                                        <td class="text-end">
                                          <input
                                            v-if="permission6.sts == 'true'"
                                            @click="
                                              singleCheckedUncheked(
                                                $event,
                                                permission6,
                                                permissions6,
                                                'distributer-wallet'
                                              )
                                            "
                                            type="checkbox"
                                            checked
                                          />
                                          <input
                                            v-if="permission6.sts == 'false'"
                                            @click="
                                              singleCheckedUncheked(
                                                $event,
                                                permission6,
                                                permissions6,
                                                'distributer-wallet'
                                              )
                                            "
                                            type="checkbox"
                                          />

                                          <span v-if="!permission6.sts">
                                            <input disabled type="checkbox" />
                                          </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item" data-v-1b249e63="">
                        <h2
                          @click="masterdistributerpermission()"
                          class="accordion-header"
                          data-v-1b249e63=""
                        >
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#master-distributer"
                            aria-expanded="false"
                            aria-controls="master-distributer"
                            data-v-1b249e63=""
                          >
                            <i
                              data-feather="plus"
                              class="accordian-plus"
                              data-v-1b249e63=""
                            ></i>
                            Master Distributer
                          </button>
                        </h2>
                        <div
                          id="master-distributer"
                          class="accordion-collapse collapse"
                          aria-labelledby="master-distributer"
                          data-bs-parent="#accordionMargin"
                          data-v-1b249e63=""
                        >
                          <div class="accordion-body" data-v-1b249e63="">
                            <div class="row">
                              <div
                                class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                style="margin-top: 7px"
                              >
                                <div class="container-fluid table-scroll">
                                  <table class="table table-hover table-sm">
                                    <tr
                                      class="text tr-head table-wrapper-scroll-y rounded-circle"
                                    >
                                      <th>+</th>

                                      <th>Name</th>

                                      <th>For</th>
                                      <th>Description</th>
                                      <th>Status</th>
                                      <th class="text-end">
                                        <input
                                          v-if="
                                            prePermissionIDs7.length ==
                                            permissions7.length
                                          "
                                          @click="
                                            allCheclSand(
                                              $event,
                                              permissions7,
                                              'master-distributer'
                                            )
                                          "
                                          type="checkbox"
                                          checked
                                        />
                                        <input
                                          v-else
                                          @click="
                                            allCheclSand(
                                              $event,
                                              permissions7,
                                              'master-distributer'
                                            )
                                          "
                                          type="checkbox"
                                        />
                                      </th>
                                    </tr>

                                    <tbody>
                                      <tr
                                        style="color: #00364f"
                                        v-for="(permission7, index) in permissions7"
                                        :key="index"
                                      >
                                        <td>
                                          {{ index + 1 }}
                                        </td>

                                        <td class="text-truncate">
                                          {{ permission7.permission }}
                                        </td>
                                        <td>{{ permission7.for }}</td>
                                        <td>{{ permission7.description }}</td>
                                        <td>{{ permission7.sts }}</td>

                                        <td class="text-end">
                                          <input
                                            v-if="permission7.sts == 'true'"
                                            @click="
                                              singleCheckedUncheked(
                                                $event,
                                                permission7,
                                                permissions7,
                                                'master-distributer'
                                              )
                                            "
                                            type="checkbox"
                                            checked
                                          />
                                          <input
                                            v-if="permission7.sts == 'false'"
                                            @click="
                                              singleCheckedUncheked(
                                                $event,
                                                permission7,
                                                permissions7,
                                                'master-distributer'
                                              )
                                            "
                                            type="checkbox"
                                          />

                                          <span v-if="!permission7.sts">
                                            <input disabled type="checkbox" />
                                          </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item" data-v-1b249e63="">
                        <h2
                          @click="distributerbeneficiarypermission()"
                          class="accordion-header"
                          data-v-1b249e63=""
                        >
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#distributer-beneficiary-account"
                            aria-expanded="false"
                            aria-controls="distributer-beneficiary-account"
                            data-v-1b249e63=""
                          >
                            <i
                              data-feather="plus"
                              class="accordian-plus"
                              data-v-1b249e63=""
                            ></i>
                            Distributer Beneficiary Account
                          </button>
                        </h2>
                        <div
                          id="distributer-beneficiary-account"
                          class="accordion-collapse collapse"
                          aria-labelledby="distributer-beneficiary-account"
                          data-bs-parent="#accordionMargin"
                          data-v-1b249e63=""
                        >
                          <div class="accordion-body" data-v-1b249e63="">
                            <div class="row">
                              <div
                                class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                style="margin-top: 7px"
                              >
                                <div class="container-fluid table-scroll">
                                  <table class="table table-hover table-sm">
                                    <tr
                                      class="text tr-head table-wrapper-scroll-y rounded-circle"
                                    >
                                      <th>+</th>

                                      <th>Name</th>

                                      <th>For</th>
                                      <th>Description</th>
                                      <th>Status</th>
                                      <th class="text-end">
                                        <input
                                          v-if="
                                            prePermissionIDs8.length ==
                                            permissions8.length
                                          "
                                          @click="
                                            allCheclSand(
                                              $event,
                                              permissions8,
                                              'distributer-beneficiary-account'
                                            )
                                          "
                                          type="checkbox"
                                          checked
                                        />
                                        <input
                                          v-else
                                          @click="
                                            allCheclSand(
                                              $event,
                                              permissions8,
                                              'distributer-beneficiary-account'
                                            )
                                          "
                                          type="checkbox"
                                        />
                                      </th>
                                    </tr>

                                    <tbody>
                                      <tr
                                        style="color: #00364f"
                                        v-for="(permission8, index) in permissions8"
                                        :key="index"
                                      >
                                        <td>
                                          {{ index + 1 }}
                                        </td>

                                        <td class="text-truncate">
                                          {{ permission8.permission }}
                                        </td>
                                        <td>{{ permission8.for }}</td>
                                        <td>{{ permission8.description }}</td>
                                        <td>{{ permission8.sts }}</td>

                                        <td class="text-end">
                                          <input
                                            v-if="permission8.sts == 'true'"
                                            @click="
                                              singleCheckedUncheked(
                                                $event,
                                                permission8,
                                                permissions8,
                                                'distributer-beneficiary-account'
                                              )
                                            "
                                            type="checkbox"
                                            checked
                                          />
                                          <input
                                            v-if="permission8.sts == 'false'"
                                            @click="
                                              singleCheckedUncheked(
                                                $event,
                                                permission8,
                                                permissions8,
                                                'distributer-beneficiary-account'
                                              )
                                            "
                                            type="checkbox"
                                          />

                                          <span v-if="!permission8.sts">
                                            <input disabled type="checkbox" />
                                          </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item" data-v-1b249e63="">
                        <h2
                          @click="masterdistributerkycpermission()"
                          class="accordion-header"
                          data-v-1b249e63=""
                        >
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#master-distributer-kyc"
                            aria-expanded="false"
                            aria-controls="master-distributer-kyc"
                            data-v-1b249e63=""
                          >
                            <i
                              data-feather="plus"
                              class="accordian-plus"
                              data-v-1b249e63=""
                            ></i>
                            Master Distributer Kyc
                          </button>
                        </h2>
                        <div
                          id="master-distributer-kyc"
                          class="accordion-collapse collapse"
                          aria-labelledby="master-distributer-kyc"
                          data-bs-parent="#accordionMargin"
                          data-v-1b249e63=""
                        >
                          <div class="accordion-body" data-v-1b249e63="">
                            <div class="row">
                              <div
                                class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                style="margin-top: 7px"
                              >
                                <div class="container-fluid table-scroll">
                                  <table class="table table-hover table-sm">
                                    <tr
                                      class="text tr-head table-wrapper-scroll-y rounded-circle"
                                    >
                                      <th>+</th>

                                      <th>Name</th>

                                      <th>For</th>
                                      <th>Description</th>
                                      <th>Status</th>
                                      <th class="text-end">
                                        <input
                                          v-if="
                                            prePermissionIDs9.length ==
                                            permissions9.length
                                          "
                                          @click="
                                            allCheclSand(
                                              $event,
                                              permissions9,
                                              'master-distributer-kyc'
                                            )
                                          "
                                          type="checkbox"
                                          checked
                                        />
                                        <input
                                          v-else
                                          @click="
                                            allCheclSand(
                                              $event,
                                              permissions9,
                                              'master-distributer-kyc'
                                            )
                                          "
                                          type="checkbox"
                                        />
                                      </th>
                                    </tr>

                                    <tbody>
                                      <tr
                                        style="color: #00364f"
                                        v-for="(permission9, index) in permissions9"
                                        :key="index"
                                      >
                                        <td>
                                          {{ index + 1 }}
                                        </td>

                                        <td class="text-truncate">
                                          {{ permission9.permission }}
                                        </td>
                                        <td>{{ permission9.for }}</td>
                                        <td>{{ permission9.description }}</td>
                                        <td>{{ permission9.sts }}</td>

                                        <td class="text-end">
                                          <input
                                            v-if="permission9.sts == 'true'"
                                            @click="
                                              singleCheckedUncheked(
                                                $event,
                                                permission9,
                                                permissions9,
                                                'master-distributer-kyc'
                                              )
                                            "
                                            type="checkbox"
                                            checked
                                          />
                                          <input
                                            v-if="permission9.sts == 'false'"
                                            @click="
                                              singleCheckedUncheked(
                                                $event,
                                                permission9,
                                                permissions9,
                                                'master-distributer-kyc'
                                              )
                                            "
                                            type="checkbox"
                                          />

                                          <span v-if="!permission9.sts">
                                            <input disabled type="checkbox" />
                                          </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item" data-v-1b249e63="">
                        <h2
                          @click="masterdistributerwalletpermission()"
                          class="accordion-header"
                          data-v-1b249e63=""
                        >
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#master-distributer-wallet"
                            aria-expanded="false"
                            aria-controls="master-distributer-wallet"
                            data-v-1b249e63=""
                          >
                            <i
                              data-feather="plus"
                              class="accordian-plus"
                              data-v-1b249e63=""
                            ></i>
                            Master Distributer Wallet
                          </button>
                        </h2>
                        <div
                          id="master-distributer-wallet"
                          class="accordion-collapse collapse"
                          aria-labelledby="master-distributer-wallet"
                          data-bs-parent="#accordionMargin"
                          data-v-1b249e63=""
                        >
                          <div class="accordion-body" data-v-1b249e63="">
                            <div class="row">
                              <div
                                class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                style="margin-top: 7px"
                              >
                                <div class="container-fluid table-scroll">
                                  <table class="table table-hover table-sm">
                                    <tr
                                      class="text tr-head table-wrapper-scroll-y rounded-circle"
                                    >
                                      <th>+</th>

                                      <th>Name</th>

                                      <th>For</th>
                                      <th>Description</th>
                                      <th>Status</th>
                                      <th class="text-end">
                                        <input
                                          v-if="
                                            prePermissionIDs10.length ==
                                            permissions10.length
                                          "
                                          @click="
                                            allCheclSand(
                                              $event,
                                              permissions10,
                                              'master-distributer-wallet'
                                            )
                                          "
                                          type="checkbox"
                                          checked
                                        />
                                        <input
                                          v-else
                                          @click="
                                            allCheclSand(
                                              $event,
                                              permissions10,
                                              'master-distributer-wallet'
                                            )
                                          "
                                          type="checkbox"
                                        />
                                      </th>
                                    </tr>

                                    <tbody>
                                      <tr
                                        style="color: #00364f"
                                        v-for="(permission10, index) in permissions10"
                                        :key="index"
                                      >
                                        <td>
                                          {{ index + 1 }}
                                        </td>

                                        <td class="text-truncate">
                                          {{ permission10.permission }}
                                        </td>
                                        <td>{{ permission10.for }}</td>
                                        <td>{{ permission10.description }}</td>
                                        <td>{{ permission10.sts }}</td>

                                        <td class="text-end">
                                          <input
                                            v-if="permission10.sts == 'true'"
                                            @click="
                                              singleCheckedUncheked(
                                                $event,
                                                permission10,
                                                permissions10,
                                                'master-distributer-wallet'
                                              )
                                            "
                                            type="checkbox"
                                            checked
                                          />
                                          <input
                                            v-if="permission10.sts == 'false'"
                                            @click="
                                              singleCheckedUncheked(
                                                $event,
                                                permission10,
                                                permissions10,
                                                'master-distributer-wallet'
                                              )
                                            "
                                            type="checkbox"
                                          />

                                          <span v-if="!permission10.sts">
                                            <input disabled type="checkbox" />
                                          </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item" data-v-1b249e63="">
                        <h2
                          @click="masterdistributerbeneficiarypermission()"
                          class="accordion-header"
                          data-v-1b249e63=""
                        >
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#master-distributer-beneficiary-account"
                            aria-expanded="false"
                            aria-controls="master-distributer-beneficiary-account"
                            data-v-1b249e63=""
                          >
                            <i
                              data-feather="plus"
                              class="accordian-plus"
                              data-v-1b249e63=""
                            ></i>
                            Master Distributer Beneficiary Account
                          </button>
                        </h2>
                        <div
                          id="master-distributer-beneficiary-account"
                          class="accordion-collapse collapse"
                          aria-labelledby="master-distributer-beneficiary-account"
                          data-bs-parent="#accordionMargin"
                          data-v-1b249e63=""
                        >
                          <div class="accordion-body" data-v-1b249e63="">
                            <div class="row">
                              <div
                                class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                style="margin-top: 7px"
                              >
                                <div class="container-fluid table-scroll">
                                  <table class="table table-hover table-sm">
                                    <tr
                                      class="text tr-head table-wrapper-scroll-y rounded-circle"
                                    >
                                      <th>+</th>

                                      <th>Name</th>

                                      <th>For</th>
                                      <th>Description</th>
                                      <th>Status</th>
                                      <th class="text-end">
                                        <input
                                          v-if="
                                            prePermissionIDs11.length ==
                                            permissions11.length
                                          "
                                          @click="
                                            allCheclSand(
                                              $event,
                                              permissions11,
                                              'master-distributer-beneficiary-account'
                                            )
                                          "
                                          type="checkbox"
                                          checked
                                        />
                                        <input
                                          v-else
                                          @click="
                                            allCheclSand(
                                              $event,
                                              permissions11,
                                              'master-distributer-beneficiary-account'
                                            )
                                          "
                                          type="checkbox"
                                        />
                                      </th>
                                    </tr>

                                    <tbody>
                                      <tr
                                        style="color: #00364f"
                                        v-for="(permission11, index) in permissions11"
                                        :key="index"
                                      >
                                        <td>
                                          {{ index + 1 }}
                                        </td>

                                        <td class="text-truncate">
                                          {{ permission11.permission }}
                                        </td>
                                        <td>{{ permission11.for }}</td>
                                        <td>{{ permission11.description }}</td>
                                        <td>{{ permission11.sts }}</td>

                                        <td class="text-end">
                                          <input
                                            v-if="permission11.sts == 'true'"
                                            @click="
                                              singleCheckedUncheked(
                                                $event,
                                                permission11,
                                                permissions11,
                                                'master-distributer-beneficiary-account'
                                              )
                                            "
                                            type="checkbox"
                                            checked
                                          />
                                          <input
                                            v-if="permission11.sts == 'false'"
                                            @click="
                                              singleCheckedUncheked(
                                                $event,
                                                permission11,
                                                permissions11,
                                                'master-distributer-beneficiary-account'
                                              )
                                            "
                                            type="checkbox"
                                          />

                                          <span v-if="!permission11.sts">
                                            <input disabled type="checkbox" />
                                          </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item" data-v-1b249e63="">
                        <h2
                          @click="professionalpermission()"
                          class="accordion-header"
                          data-v-1b249e63=""
                        >
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#professional"
                            aria-expanded="false"
                            aria-controls="professional"
                            data-v-1b249e63=""
                          >
                            <i
                              data-feather="plus"
                              class="accordian-plus"
                              data-v-1b249e63=""
                            ></i>
                            Professional
                          </button>
                        </h2>
                        <div
                          id="professional"
                          class="accordion-collapse collapse"
                          aria-labelledby="professional"
                          data-bs-parent="#accordionMargin"
                          data-v-1b249e63=""
                        >
                          <div class="accordion-body" data-v-1b249e63="">
                            <div class="row">
                              <div
                                class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                style="margin-top: 7px"
                              >
                                <div class="container-fluid table-scroll">
                                  <table class="table table-hover table-sm">
                                    <tr
                                      class="text tr-head table-wrapper-scroll-y rounded-circle"
                                    >
                                      <th>+</th>

                                      <th>Name</th>

                                      <th>For</th>
                                      <th>Description</th>
                                      <th>Status</th>
                                      <th class="text-end">
                                        <input
                                          v-if="
                                            prePermissionIDs12.length ==
                                            permissions12.length
                                          "
                                          @click="
                                            allCheclSand(
                                              $event,
                                              permissions12,
                                              'professional'
                                            )
                                          "
                                          type="checkbox"
                                          checked
                                        />
                                        <input
                                          v-else
                                          @click="
                                            allCheclSand(
                                              $event,
                                              permissions12,
                                              'professional'
                                            )
                                          "
                                          type="checkbox"
                                        />
                                      </th>
                                    </tr>

                                    <tbody>
                                      <tr
                                        style="color: #00364f"
                                        v-for="(permission12, index) in permissions12"
                                        :key="index"
                                      >
                                        <td>
                                          {{ index + 1 }}
                                        </td>

                                        <td class="text-truncate">
                                          {{ permission12.permission }}
                                        </td>
                                        <td>{{ permission12.for }}</td>
                                        <td>{{ permission12.description }}</td>
                                        <td>{{ permission12.sts }}</td>

                                        <td class="text-end">
                                          <input
                                            v-if="permission12.sts == 'true'"
                                            @click="
                                              singleCheckedUncheked(
                                                $event,
                                                permission12,
                                                permissions12,
                                                'professional'
                                              )
                                            "
                                            type="checkbox"
                                            checked
                                          />
                                          <input
                                            v-if="permission12.sts == 'false'"
                                            @click="
                                              singleCheckedUncheked(
                                                $event,
                                                permission12,
                                                permissions12,
                                                'professional'
                                              )
                                            "
                                            type="checkbox"
                                          />

                                          <span v-if="!permission12.sts">
                                            <input disabled type="checkbox" />
                                          </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item" data-v-1b249e63="">
                        <h2
                          @click="professionalkycpermission()"
                          class="accordion-header"
                          data-v-1b249e63=""
                        >
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#professional-kyc"
                            aria-expanded="false"
                            aria-controls="professional-kyc"
                            data-v-1b249e63=""
                          >
                            <i
                              data-feather="plus"
                              class="accordian-plus"
                              data-v-1b249e63=""
                            ></i>
                            Professional Kyc
                          </button>
                        </h2>
                        <div
                          id="professional-kyc"
                          class="accordion-collapse collapse"
                          aria-labelledby="professional-kyc"
                          data-bs-parent="#accordionMargin"
                          data-v-1b249e63=""
                        >
                          <div class="accordion-body" data-v-1b249e63="">
                            <div class="row">
                              <div
                                class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                style="margin-top: 7px"
                              >
                                <div class="container-fluid table-scroll">
                                  <table class="table table-hover table-sm">
                                    <tr
                                      class="text tr-head table-wrapper-scroll-y rounded-circle"
                                    >
                                      <th>+</th>

                                      <th>Name</th>

                                      <th>For</th>
                                      <th>Description</th>
                                      <th>Status</th>
                                      <th class="text-end">
                                        <input
                                          v-if="
                                            prePermissionIDs13.length ==
                                            permissions13.length
                                          "
                                          @click="
                                            allCheclSand(
                                              $event,
                                              permissions13,
                                              'professional-kyc'
                                            )
                                          "
                                          type="checkbox"
                                          checked
                                        />
                                        <input
                                          v-else
                                          @click="
                                            allCheclSand(
                                              $event,
                                              permissions13,
                                              'professional-kyc'
                                            )
                                          "
                                          type="checkbox"
                                        />
                                      </th>
                                    </tr>

                                    <tbody>
                                      <tr
                                        style="color: #00364f"
                                        v-for="(permission13, index) in permissions13"
                                        :key="index"
                                      >
                                        <td>
                                          {{ index + 1 }}
                                        </td>

                                        <td class="text-truncate">
                                          {{ permission13.permission }}
                                        </td>
                                        <td>{{ permission13.for }}</td>
                                        <td>{{ permission13.description }}</td>
                                        <td>{{ permission13.sts }}</td>

                                        <td class="text-end">
                                          <input
                                            v-if="permission13.sts == 'true'"
                                            @click="
                                              singleCheckedUncheked(
                                                $event,
                                                permission13,
                                                permissions13,
                                                'professional-kyc'
                                              )
                                            "
                                            type="checkbox"
                                            checked
                                          />
                                          <input
                                            v-if="permission13.sts == 'false'"
                                            @click="
                                              singleCheckedUncheked(
                                                $event,
                                                permission13,
                                                permissions13,
                                                'professional-kyc'
                                              )
                                            "
                                            type="checkbox"
                                          />

                                          <span v-if="!permission13.sts">
                                            <input disabled type="checkbox" />
                                          </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item" data-v-1b249e63="">
                        <h2
                          @click="professionalbeneficiarypermission()"
                          class="accordion-header"
                          data-v-1b249e63=""
                        >
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#professional-beneficiary-account"
                            aria-expanded="false"
                            aria-controls="professional-beneficiary-account"
                            data-v-1b249e63=""
                          >
                            <i
                              data-feather="plus"
                              class="accordian-plus"
                              data-v-1b249e63=""
                            ></i>
                            Professional Beneficiary Account
                          </button>
                        </h2>
                        <div
                          id="professional-beneficiary-account"
                          class="accordion-collapse collapse"
                          aria-labelledby="professional-beneficiary-account"
                          data-bs-parent="#accordionMargin"
                          data-v-1b249e63=""
                        >
                          <div class="accordion-body" data-v-1b249e63="">
                            <div class="row">
                              <div
                                class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                style="margin-top: 7px"
                              >
                                <div class="container-fluid table-scroll">
                                  <table class="table table-hover table-sm">
                                    <tr
                                      class="text tr-head table-wrapper-scroll-y rounded-circle"
                                    >
                                      <th>+</th>

                                      <th>Name</th>

                                      <th>For</th>
                                      <th>Description</th>
                                      <th>Status</th>
                                      <th class="text-end">
                                        <input
                                          v-if="
                                            prePermissionIDs14.length ==
                                            permissions14.length
                                          "
                                          @click="
                                            allCheclSand(
                                              $event,
                                              permissions14,
                                              '	professional-beneficiary-account'
                                            )
                                          "
                                          type="checkbox"
                                          checked
                                        />
                                        <input
                                          v-else
                                          @click="
                                            allCheclSand(
                                              $event,
                                              permissions14,
                                              '	professional-beneficiary-account'
                                            )
                                          "
                                          type="checkbox"
                                        />
                                      </th>
                                    </tr>

                                    <tbody>
                                      <tr
                                        style="color: #00364f"
                                        v-for="(permission14, index) in permissions14"
                                        :key="index"
                                      >
                                        <td>
                                          {{ index + 1 }}
                                        </td>

                                        <td class="text-truncate">
                                          {{ permission14.permission }}
                                        </td>
                                        <td>{{ permission14.for }}</td>
                                        <td>{{ permission14.description }}</td>
                                        <td>{{ permission14.sts }}</td>

                                        <td class="text-end">
                                          <input
                                            v-if="permission14.sts == 'true'"
                                            @click="
                                              singleCheckedUncheked(
                                                $event,
                                                permission14,
                                                permissions14,
                                                'professional-beneficiary-account'
                                              )
                                            "
                                            type="checkbox"
                                            checked
                                          />
                                          <input
                                            v-if="permission14.sts == 'false'"
                                            @click="
                                              singleCheckedUncheked(
                                                $event,
                                                permission14,
                                                permissions14,
                                                'professional-beneficiary-account'
                                              )
                                            "
                                            type="checkbox"
                                          />

                                          <span v-if="!permission14.sts">
                                            <input disabled type="checkbox" />
                                          </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item" data-v-1b249e63="">
                        <h2
                          @click="professionaltypepermission()"
                          class="accordion-header"
                          data-v-1b249e63=""
                        >
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#professional-type"
                            aria-expanded="false"
                            aria-controls="professional-type"
                            data-v-1b249e63=""
                          >
                            <i
                              data-feather="plus"
                              class="accordian-plus"
                              data-v-1b249e63=""
                            ></i>
                            Professional Type
                          </button>
                        </h2>
                        <div
                          id="professional-type"
                          class="accordion-collapse collapse"
                          aria-labelledby="professional-type"
                          data-bs-parent="#accordionMargin"
                          data-v-1b249e63=""
                        >
                          <div class="accordion-body" data-v-1b249e63="">
                            <div class="row">
                              <div
                                class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                style="margin-top: 7px"
                              >
                                <div class="container-fluid table-scroll">
                                  <table class="table table-hover table-sm">
                                    <tr
                                      class="text tr-head table-wrapper-scroll-y rounded-circle"
                                    >
                                      <th>+</th>

                                      <th>Name</th>

                                      <th>For</th>
                                      <th>Description</th>
                                      <th>Status</th>
                                      <th class="text-end">
                                        <input
                                          v-if="
                                            prePermissionIDs15.length ==
                                            permissions15.length
                                          "
                                          @click="
                                            allCheclSand(
                                              $event,
                                              permissions15,
                                              '	professional-type'
                                            )
                                          "
                                          type="checkbox"
                                          checked
                                        />
                                        <input
                                          v-else
                                          @click="
                                            allCheclSand(
                                              $event,
                                              permissions15,
                                              '	professional-type'
                                            )
                                          "
                                          type="checkbox"
                                        />
                                      </th>
                                    </tr>

                                    <tbody>
                                      <tr
                                        style="color: #00364f"
                                        v-for="(permission15, index) in permissions15"
                                        :key="index"
                                      >
                                        <td>
                                          {{ index + 1 }}
                                        </td>

                                        <td class="text-truncate">
                                          {{ permission15.permission }}
                                        </td>
                                        <td>{{ permission15.for }}</td>
                                        <td>{{ permission15.description }}</td>
                                        <td>{{ permission15.sts }}</td>

                                        <td class="text-end">
                                          <input
                                            v-if="permission15.sts == 'true'"
                                            @click="
                                              singleCheckedUncheked(
                                                $event,
                                                permission15,
                                                permissions15,
                                                'professional-type'
                                              )
                                            "
                                            type="checkbox"
                                            checked
                                          />
                                          <input
                                            v-if="permission15.sts == 'false'"
                                            @click="
                                              singleCheckedUncheked(
                                                $event,
                                                permission15,
                                                permissions15,
                                                'professional-type'
                                              )
                                            "
                                            type="checkbox"
                                          />

                                          <span v-if="!permission15.sts">
                                            <input disabled type="checkbox" />
                                          </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item" data-v-1b249e63="">
                        <h2
                          @click="retailerpermission()"
                          class="accordion-header"
                          data-v-1b249e63=""
                        >
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#retailer"
                            aria-expanded="false"
                            aria-controls="retailer"
                            data-v-1b249e63=""
                          >
                            <i
                              data-feather="plus"
                              class="accordian-plus"
                              data-v-1b249e63=""
                            ></i>
                            Retailer
                          </button>
                        </h2>
                        <div
                          id="retailer"
                          class="accordion-collapse collapse"
                          aria-labelledby="retailer"
                          data-bs-parent="#accordionMargin"
                          data-v-1b249e63=""
                        >
                          <div class="accordion-body" data-v-1b249e63="">
                            <div class="row">
                              <div
                                class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                style="margin-top: 7px"
                              >
                                <div class="container-fluid table-scroll">
                                  <table class="table table-hover table-sm">
                                    <tr
                                      class="text tr-head table-wrapper-scroll-y rounded-circle"
                                    >
                                      <th>+</th>

                                      <th>Name</th>

                                      <th>For</th>
                                      <th>Description</th>
                                      <th>Status</th>
                                      <th class="text-end">
                                        <input
                                          v-if="
                                            prePermissionIDs16.length ==
                                            permissions16.length
                                          "
                                          @click="
                                            allCheclSand(
                                              $event,
                                              permissions16,
                                              'retailer'
                                            )
                                          "
                                          type="checkbox"
                                          checked
                                        />
                                        <input
                                          v-else
                                          @click="
                                            allCheclSand(
                                              $event,
                                              permissions16,
                                              'retailer'
                                            )
                                          "
                                          type="checkbox"
                                        />
                                      </th>
                                    </tr>

                                    <tbody>
                                      <tr
                                        style="color: #00364f"
                                        v-for="(permission16, index) in permissions16"
                                        :key="index"
                                      >
                                        <td>
                                          {{ index + 1 }}
                                        </td>

                                        <td class="text-truncate">
                                          {{ permission16.permission }}
                                        </td>
                                        <td>{{ permission16.for }}</td>
                                        <td>{{ permission16.description }}</td>
                                        <td>{{ permission16.sts }}</td>

                                        <td class="text-end">
                                          <input
                                            v-if="permission16.sts == 'true'"
                                            @click="
                                              singleCheckedUncheked(
                                                $event,
                                                permission16,
                                                permissions16,
                                                'retailer'
                                              )
                                            "
                                            type="checkbox"
                                            checked
                                          />
                                          <input
                                            v-if="permission16.sts == 'false'"
                                            @click="
                                              singleCheckedUncheked(
                                                $event,
                                                permission16,
                                                permissions16,
                                                'retailer'
                                              )
                                            "
                                            type="checkbox"
                                          />

                                          <span v-if="!permission16.sts">
                                            <input disabled type="checkbox" />
                                          </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item" data-v-1b249e63="">
                        <h2
                          @click="retailerkycpermission()"
                          class="accordion-header"
                          data-v-1b249e63=""
                        >
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#retailer-kyc"
                            aria-expanded="false"
                            aria-controls="retailer-kyc"
                            data-v-1b249e63=""
                          >
                            <i
                              data-feather="plus"
                              class="accordian-plus"
                              data-v-1b249e63=""
                            ></i>
                            Retailer Kyc
                          </button>
                        </h2>
                        <div
                          id="retailer-kyc"
                          class="accordion-collapse collapse"
                          aria-labelledby="retailer-kyc"
                          data-bs-parent="#accordionMargin"
                          data-v-1b249e63=""
                        >
                          <div class="accordion-body" data-v-1b249e63="">
                            <div class="row">
                              <div
                                class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                style="margin-top: 7px"
                              >
                                <div class="container-fluid table-scroll">
                                  <table class="table table-hover table-sm">
                                    <tr
                                      class="text tr-head table-wrapper-scroll-y rounded-circle"
                                    >
                                      <th>+</th>

                                      <th>Name</th>

                                      <th>For</th>
                                      <th>Description</th>
                                      <th>Status</th>
                                      <th class="text-end">
                                        <input
                                          v-if="
                                            prePermissionIDs17.length ==
                                            permissions17.length
                                          "
                                          @click="
                                            allCheclSand(
                                              $event,
                                              permissions17,
                                              'retailer-kyc'
                                            )
                                          "
                                          type="checkbox"
                                          checked
                                        />
                                        <input
                                          v-else
                                          @click="
                                            allCheclSand(
                                              $event,
                                              permissions17,
                                              'retailer-kyc'
                                            )
                                          "
                                          type="checkbox"
                                        />
                                      </th>
                                    </tr>

                                    <tbody>
                                      <tr
                                        style="color: #00364f"
                                        v-for="(permission17, index) in permissions17"
                                        :key="index"
                                      >
                                        <td>
                                          {{ index + 1 }}
                                        </td>

                                        <td class="text-truncate">
                                          {{ permission17.permission }}
                                        </td>
                                        <td>{{ permission17.for }}</td>
                                        <td>{{ permission17.description }}</td>
                                        <td>{{ permission17.sts }}</td>

                                        <td class="text-end">
                                          <input
                                            v-if="permission17.sts == 'true'"
                                            @click="
                                              singleCheckedUncheked(
                                                $event,
                                                permission17,
                                                permissions17,
                                                'retailer-kyc'
                                              )
                                            "
                                            type="checkbox"
                                            checked
                                          />
                                          <input
                                            v-if="permission17.sts == 'false'"
                                            @click="
                                              singleCheckedUncheked(
                                                $event,
                                                permission17,
                                                permissions17,
                                                'retailer-kyc'
                                              )
                                            "
                                            type="checkbox"
                                          />

                                          <span v-if="!permission17.sts">
                                            <input disabled type="checkbox" />
                                          </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item" data-v-1b249e63="">
                        <h2
                          @click="retailerwalletpermission()"
                          class="accordion-header"
                          data-v-1b249e63=""
                        >
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#retailer-wallet"
                            aria-expanded="false"
                            aria-controls="retailer-wallet"
                            data-v-1b249e63=""
                          >
                            <i
                              data-feather="plus"
                              class="accordian-plus"
                              data-v-1b249e63=""
                            ></i>
                            Retailer Wallet
                          </button>
                        </h2>
                        <div
                          id="retailer-wallet"
                          class="accordion-collapse collapse"
                          aria-labelledby="retailer-wallet"
                          data-bs-parent="#accordionMargin"
                          data-v-1b249e63=""
                        >
                          <div class="accordion-body" data-v-1b249e63="">
                            <div class="row">
                              <div
                                class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                style="margin-top: 7px"
                              >
                                <div class="container-fluid table-scroll">
                                  <table class="table table-hover table-sm">
                                    <tr
                                      class="text tr-head table-wrapper-scroll-y rounded-circle"
                                    >
                                      <th>+</th>

                                      <th>Name</th>

                                      <th>For</th>
                                      <th>Description</th>
                                      <th>Status</th>
                                      <th class="text-end">
                                        <input
                                          v-if="
                                            prePermissionIDs18.length ==
                                            permissions18.length
                                          "
                                          @click="
                                            allCheclSand(
                                              $event,
                                              permissions18,
                                              'retailer-wallet'
                                            )
                                          "
                                          type="checkbox"
                                          checked
                                        />
                                        <input
                                          v-else
                                          @click="
                                            allCheclSand(
                                              $event,
                                              permissions18,
                                              'retailer-wallet'
                                            )
                                          "
                                          type="checkbox"
                                        />
                                      </th>
                                    </tr>

                                    <tbody>
                                      <tr
                                        style="color: #00364f"
                                        v-for="(permission18, index) in permissions18"
                                        :key="index"
                                      >
                                        <td>
                                          {{ index + 1 }}
                                        </td>

                                        <td class="text-truncate">
                                          {{ permission18.permission }}
                                        </td>
                                        <td>{{ permission18.for }}</td>
                                        <td>{{ permission18.description }}</td>
                                        <td>{{ permission18.sts }}</td>

                                        <td class="text-end">
                                          <input
                                            v-if="permission18.sts == 'true'"
                                            @click="
                                              singleCheckedUncheked(
                                                $event,
                                                permission18,
                                                permissions18,
                                                'retailer-wallet'
                                              )
                                            "
                                            type="checkbox"
                                            checked
                                          />
                                          <input
                                            v-if="permission18.sts == 'false'"
                                            @click="
                                              singleCheckedUncheked(
                                                $event,
                                                permission18,
                                                permissions18,
                                                'retailer-wallet'
                                              )
                                            "
                                            type="checkbox"
                                          />

                                          <span v-if="!permission18.sts">
                                            <input disabled type="checkbox" />
                                          </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item" data-v-1b249e63="">
                        <h2
                          @click="retailerbeneficiarypermission()"
                          class="accordion-header"
                          data-v-1b249e63=""
                        >
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#retailer-beneficiary-account"
                            aria-expanded="false"
                            aria-controls="retailer-beneficiary-account"
                            data-v-1b249e63=""
                          >
                            <i
                              data-feather="plus"
                              class="accordian-plus"
                              data-v-1b249e63=""
                            ></i>
                            Retailer Beneficiary Account
                          </button>
                        </h2>
                        <div
                          id="retailer-beneficiary-account"
                          class="accordion-collapse collapse"
                          aria-labelledby="retailer-beneficiary-account"
                          data-bs-parent="#accordionMargin"
                          data-v-1b249e63=""
                        >
                          <div class="accordion-body" data-v-1b249e63="">
                            <div class="row">
                              <div
                                class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                style="margin-top: 7px"
                              >
                                <div class="container-fluid table-scroll">
                                  <table class="table table-hover table-sm">
                                    <tr
                                      class="text tr-head table-wrapper-scroll-y rounded-circle"
                                    >
                                      <th>+</th>

                                      <th>Name</th>

                                      <th>For</th>
                                      <th>Description</th>
                                      <th>Status</th>
                                      <th class="text-end">
                                        <input
                                          v-if="
                                            prePermissionIDs19.length ==
                                            permissions19.length
                                          "
                                          @click="
                                            allCheclSand(
                                              $event,
                                              permissions19,
                                              'retailer-beneficiary-account'
                                            )
                                          "
                                          type="checkbox"
                                          checked
                                        />
                                        <input
                                          v-else
                                          @click="
                                            allCheclSand(
                                              $event,
                                              permissions19,
                                              'retailer-beneficiary-account'
                                            )
                                          "
                                          type="checkbox"
                                        />
                                      </th>
                                    </tr>

                                    <tbody>
                                      <tr
                                        style="color: #00364f"
                                        v-for="(permission19, index) in permissions19"
                                        :key="index"
                                      >
                                        <td>
                                          {{ index + 1 }}
                                        </td>

                                        <td class="text-truncate">
                                          {{ permission19.permission }}
                                        </td>
                                        <td>{{ permission19.for }}</td>
                                        <td>{{ permission19.description }}</td>
                                        <td>{{ permission19.sts }}</td>

                                        <td class="text-end">
                                          <input
                                            v-if="permission19.sts == 'true'"
                                            @click="
                                              singleCheckedUncheked(
                                                $event,
                                                permission19,
                                                permissions19,
                                                'retailer-beneficiary-account'
                                              )
                                            "
                                            type="checkbox"
                                            checked
                                          />
                                          <input
                                            v-if="permission19.sts == 'false'"
                                            @click="
                                              singleCheckedUncheked(
                                                $event,
                                                permission19,
                                                permissions19,
                                                'retailer-beneficiary-account'
                                              )
                                            "
                                            type="checkbox"
                                          />

                                          <span v-if="!permission19.sts">
                                            <input disabled type="checkbox" />
                                          </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item" data-v-1b249e63="">
                        <h2
                          @click="staffpermission()"
                          class="accordion-header"
                          data-v-1b249e63=""
                        >
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#staff"
                            aria-expanded="false"
                            aria-controls="staff"
                            data-v-1b249e63=""
                          >
                            <i
                              data-feather="plus"
                              class="accordian-plus"
                              data-v-1b249e63=""
                            ></i>
                            Staff
                          </button>
                        </h2>
                        <div
                          id="staff"
                          class="accordion-collapse collapse"
                          aria-labelledby="staff"
                          data-bs-parent="#accordionMargin"
                          data-v-1b249e63=""
                        >
                          <div class="accordion-body" data-v-1b249e63="">
                            <div class="row">
                              <div
                                class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                style="margin-top: 7px"
                              >
                                <div class="container-fluid table-scroll">
                                  <table class="table table-hover table-sm">
                                    <tr
                                      class="text tr-head table-wrapper-scroll-y rounded-circle"
                                    >
                                      <th>+</th>

                                      <th>Name</th>

                                      <th>For</th>
                                      <th>Description</th>
                                      <th>Status</th>
                                      <th class="text-end">
                                        <input
                                          v-if="
                                            prePermissionIDs20.length ==
                                            permissions20.length
                                          "
                                          @click="
                                            allCheclSand($event, permissions20, '	staff')
                                          "
                                          type="checkbox"
                                          checked
                                        />
                                        <input
                                          v-else
                                          @click="
                                            allCheclSand($event, permissions20, '	staff')
                                          "
                                          type="checkbox"
                                        />
                                      </th>
                                    </tr>

                                    <tbody>
                                      <tr
                                        style="color: #00364f"
                                        v-for="(permission20, index) in permissions20"
                                        :key="index"
                                      >
                                        <td>
                                          {{ index + 1 }}
                                        </td>

                                        <td class="text-truncate">
                                          {{ permission20.permission }}
                                        </td>
                                        <td>{{ permission20.for }}</td>
                                        <td>{{ permission20.description }}</td>
                                        <td>{{ permission20.sts }}</td>

                                        <td class="text-end">
                                          <input
                                            v-if="permission20.sts == 'true'"
                                            @click="
                                              singleCheckedUncheked(
                                                $event,
                                                permission20,
                                                permissions20,
                                                'staff'
                                              )
                                            "
                                            type="checkbox"
                                            checked
                                          />
                                          <input
                                            v-if="permission20.sts == 'false'"
                                            @click="
                                              singleCheckedUncheked(
                                                $event,
                                                permission20,
                                                permissions20,
                                                'staff'
                                              )
                                            "
                                            type="checkbox"
                                          />

                                          <span v-if="!permission20.sts">
                                            <input disabled type="checkbox" />
                                          </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item" data-v-1b249e63="">
                        <h2
                          @click="staffkycpermission()"
                          class="accordion-header"
                          data-v-1b249e63=""
                        >
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#staff-kyc"
                            aria-expanded="false"
                            aria-controls="staff-kyc"
                            data-v-1b249e63=""
                          >
                            <i
                              data-feather="plus"
                              class="accordian-plus"
                              data-v-1b249e63=""
                            ></i>
                            Staff Kyc
                          </button>
                        </h2>
                        <div
                          id="staff-kyc"
                          class="accordion-collapse collapse"
                          aria-labelledby="staff-kyc"
                          data-bs-parent="#accordionMargin"
                          data-v-1b249e63=""
                        >
                          <div class="accordion-body" data-v-1b249e63="">
                            <div class="row">
                              <div
                                class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                style="margin-top: 7px"
                              >
                                <div class="container-fluid table-scroll">
                                  <table class="table table-hover table-sm">
                                    <tr
                                      class="text tr-head table-wrapper-scroll-y rounded-circle"
                                    >
                                      <th>+</th>

                                      <th>Name</th>

                                      <th>For</th>
                                      <th>Description</th>
                                      <th>Status</th>
                                      <th class="text-end">
                                        <input
                                          v-if="
                                            prePermissionIDs21.length ==
                                            permissions21.length
                                          "
                                          @click="
                                            allCheclSand(
                                              $event,
                                              permissions21,
                                              'staff-kyc'
                                            )
                                          "
                                          type="checkbox"
                                          checked
                                        />
                                        <input
                                          v-else
                                          @click="
                                            allCheclSand(
                                              $event,
                                              permissions21,
                                              'staff-kyc'
                                            )
                                          "
                                          type="checkbox"
                                        />
                                      </th>
                                    </tr>

                                    <tbody>
                                      <tr
                                        style="color: #00364f"
                                        v-for="(permission21, index) in permissions21"
                                        :key="index"
                                      >
                                        <td>
                                          {{ index + 1 }}
                                        </td>

                                        <td class="text-truncate">
                                          {{ permission21.permission }}
                                        </td>
                                        <td>{{ permission21.for }}</td>
                                        <td>{{ permission21.description }}</td>
                                        <td>{{ permission21.sts }}</td>

                                        <td class="text-end">
                                          <input
                                            v-if="permission21.sts == 'true'"
                                            @click="
                                              singleCheckedUncheked(
                                                $event,
                                                permission21,
                                                permissions21,
                                                'staff-kyc'
                                              )
                                            "
                                            type="checkbox"
                                            checked
                                          />
                                          <input
                                            v-if="permission21.sts == 'false'"
                                            @click="
                                              singleCheckedUncheked(
                                                $event,
                                                permission21,
                                                permissions21,
                                                'staff-kyc'
                                              )
                                            "
                                            type="checkbox"
                                          />

                                          <span v-if="!permission21.sts">
                                            <input disabled type="checkbox" />
                                          </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item" data-v-1b249e63="">
                        <h2
                          @click="clientservicepermission()"
                          class="accordion-header"
                          data-v-1b249e63=""
                        >
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#clientservice"
                            aria-expanded="false"
                            aria-controls="clientservice"
                            data-v-1b249e63=""
                          >
                            <i
                              data-feather="plus"
                              class="accordian-plus"
                              data-v-1b249e63=""
                            ></i>
                            Clientservice
                          </button>
                        </h2>
                        <div
                          id="clientservice"
                          class="accordion-collapse collapse"
                          aria-labelledby="clientservice"
                          data-bs-parent="#accordionMargin"
                          data-v-1b249e63=""
                        >
                          <div class="accordion-body" data-v-1b249e63="">
                            <div class="row">
                              <div
                                class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                style="margin-top: 7px"
                              >
                                <div class="container-fluid table-scroll">
                                  <table class="table table-hover table-sm">
                                    <tr
                                      class="text tr-head table-wrapper-scroll-y rounded-circle"
                                    >
                                      <th>+</th>

                                      <th>Name</th>

                                      <th>For</th>
                                      <th>Description</th>
                                      <th>Status</th>
                                      <th class="text-end">
                                        <input
                                          v-if="
                                            prePermissionIDs22.length ==
                                            permissions22.length
                                          "
                                          @click="
                                            allCheclSand(
                                              $event,
                                              permissions22,
                                              'clientservice'
                                            )
                                          "
                                          type="checkbox"
                                          checked
                                        />
                                        <input
                                          v-else
                                          @click="
                                            allCheclSand(
                                              $event,
                                              permissions22,
                                              'clientservice'
                                            )
                                          "
                                          type="checkbox"
                                        />
                                      </th>
                                    </tr>

                                    <tbody>
                                      <tr
                                        style="color: #00364f"
                                        v-for="(permission22, index) in permissions22"
                                        :key="index"
                                      >
                                        <td>
                                          {{ index + 1 }}
                                        </td>

                                        <td class="text-truncate">
                                          {{ permission22.permission }}
                                        </td>
                                        <td>{{ permission22.for }}</td>
                                        <td>{{ permission22.description }}</td>
                                        <td>{{ permission22.sts }}</td>

                                        <td class="text-end">
                                          <input
                                            v-if="permission22.sts == 'true'"
                                            @click="
                                              singleCheckedUncheked(
                                                $event,
                                                permission22,
                                                permissions22,
                                                'clientservice'
                                              )
                                            "
                                            type="checkbox"
                                            checked
                                          />
                                          <input
                                            v-if="permission22.sts == 'false'"
                                            @click="
                                              singleCheckedUncheked(
                                                $event,
                                                permission22,
                                                permissions22,
                                                'clientservice'
                                              )
                                            "
                                            type="checkbox"
                                          />

                                          <span v-if="!permission22.sts">
                                            <input disabled type="checkbox" />
                                          </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item" data-v-1b249e63="">
                        <h2
                          @click="clientservicechallanpermission()"
                          class="accordion-header"
                          data-v-1b249e63=""
                        >
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#clientservice-challan"
                            aria-expanded="false"
                            aria-controls="clientservice-challan"
                            data-v-1b249e63=""
                          >
                            <i
                              data-feather="plus"
                              class="accordian-plus"
                              data-v-1b249e63=""
                            ></i>
                            Clientservice Challan
                          </button>
                        </h2>
                        <div
                          id="clientservice-challan"
                          class="accordion-collapse collapse"
                          aria-labelledby="clientservice-challan"
                          data-bs-parent="#accordionMargin"
                          data-v-1b249e63=""
                        >
                          <div class="accordion-body" data-v-1b249e63="">
                            <div class="row">
                              <div
                                class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                style="margin-top: 7px"
                              >
                                <div class="container-fluid table-scroll">
                                  <table class="table table-hover table-sm">
                                    <tr
                                      class="text tr-head table-wrapper-scroll-y rounded-circle"
                                    >
                                      <th>+</th>

                                      <th>Name</th>

                                      <th>For</th>
                                      <th>Description</th>
                                      <th>Status</th>
                                      <th class="text-end">
                                        <input
                                          v-if="
                                            prePermissionIDs23.length ==
                                            permissions23.length
                                          "
                                          @click="
                                            allCheclSand(
                                              $event,
                                              permissions23,
                                              'clientservice-challan'
                                            )
                                          "
                                          type="checkbox"
                                          checked
                                        />
                                        <input
                                          v-else
                                          @click="
                                            allCheclSand(
                                              $event,
                                              permissions23,
                                              'clientservice-challan'
                                            )
                                          "
                                          type="checkbox"
                                        />
                                      </th>
                                    </tr>

                                    <tbody>
                                      <tr
                                        style="color: #00364f"
                                        v-for="(permission23, index) in permissions23"
                                        :key="index"
                                      >
                                        <td>
                                          {{ index + 1 }}
                                        </td>

                                        <td class="text-truncate">
                                          {{ permission23.permission }}
                                        </td>
                                        <td>{{ permission23.for }}</td>
                                        <td>{{ permission23.description }}</td>
                                        <td>{{ permission23.sts }}</td>

                                        <td class="text-end">
                                          <input
                                            v-if="permission23.sts == 'true'"
                                            @click="
                                              singleCheckedUncheked(
                                                $event,
                                                permission23,
                                                permissions23,
                                                'clientservice-challan'
                                              )
                                            "
                                            type="checkbox"
                                            checked
                                          />
                                          <input
                                            v-if="permission23.sts == 'false'"
                                            @click="
                                              singleCheckedUncheked(
                                                $event,
                                                permission23,
                                                permissions23,
                                                'clientservice-challan'
                                              )
                                            "
                                            type="checkbox"
                                          />

                                          <span v-if="!permission23.sts">
                                            <input disabled type="checkbox" />
                                          </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item" data-v-1b249e63="">
                        <h2
                          @click="clientservicecommentpermission()"
                          class="accordion-header"
                          data-v-1b249e63=""
                        >
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#clientservice-comment"
                            aria-expanded="false"
                            aria-controls="clientservice-comment"
                            data-v-1b249e63=""
                          >
                            <i
                              data-feather="plus"
                              class="accordian-plus"
                              data-v-1b249e63=""
                            ></i>
                            Clientservice Comment
                          </button>
                        </h2>
                        <div
                          id="clientservice-comment"
                          class="accordion-collapse collapse"
                          aria-labelledby="clientservice-comment"
                          data-bs-parent="#accordionMargin"
                          data-v-1b249e63=""
                        >
                          <div class="accordion-body" data-v-1b249e63="">
                            <div class="row">
                              <div
                                class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                style="margin-top: 7px"
                              >
                                <div class="container-fluid table-scroll">
                                  <table class="table table-hover table-sm">
                                    <tr
                                      class="text tr-head table-wrapper-scroll-y rounded-circle"
                                    >
                                      <th>+</th>

                                      <th>Name</th>

                                      <th>For</th>
                                      <th>Description</th>
                                      <th>Status</th>
                                      <th class="text-end">
                                        <input
                                          v-if="
                                            prePermissionIDs24.length ==
                                            permissions24.length
                                          "
                                          @click="
                                            allCheclSand(
                                              $event,
                                              permissions24,
                                              'clientservice-comment'
                                            )
                                          "
                                          type="checkbox"
                                          checked
                                        />
                                        <input
                                          v-else
                                          @click="
                                            allCheclSand(
                                              $event,
                                              permissions24,
                                              'clientservice-comment'
                                            )
                                          "
                                          type="checkbox"
                                        />
                                      </th>
                                    </tr>

                                    <tbody>
                                      <tr
                                        style="color: #00364f"
                                        v-for="(permission24, index) in permissions24"
                                        :key="index"
                                      >
                                        <td>
                                          {{ index + 1 }}
                                        </td>

                                        <td class="text-truncate">
                                          {{ permission24.permission }}
                                        </td>
                                        <td>{{ permission24.for }}</td>
                                        <td>{{ permission24.description }}</td>
                                        <td>{{ permission24.sts }}</td>

                                        <td class="text-end">
                                          <input
                                            v-if="permission24.sts == 'true'"
                                            @click="
                                              singleCheckedUncheked(
                                                $event,
                                                permission24,
                                                permissions24,
                                                'clientservice-comment'
                                              )
                                            "
                                            type="checkbox"
                                            checked
                                          />
                                          <input
                                            v-if="permission24.sts == 'false'"
                                            @click="
                                              singleCheckedUncheked(
                                                $event,
                                                permission24,
                                                permissions24,
                                                'clientservice-comment'
                                              )
                                            "
                                            type="checkbox"
                                          />

                                          <span v-if="!permission24.sts">
                                            <input disabled type="checkbox" />
                                          </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item" data-v-1b249e63="">
                        <h2
                          @click="clientservicecertificatepermission()"
                          class="accordion-header"
                          data-v-1b249e63=""
                        >
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#clientservice-certificate"
                            aria-expanded="false"
                            aria-controls="clientservice-certificate"
                            data-v-1b249e63=""
                          >
                            <i
                              data-feather="plus"
                              class="accordian-plus"
                              data-v-1b249e63=""
                            ></i>
                            Clientservice Certificate
                          </button>
                        </h2>
                        <div
                          id="clientservice-certificate"
                          class="accordion-collapse collapse"
                          aria-labelledby="clientservice-certificate"
                          data-bs-parent="#accordionMargin"
                          data-v-1b249e63=""
                        >
                          <div class="accordion-body" data-v-1b249e63="">
                            <div class="row">
                              <div
                                class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                style="margin-top: 7px"
                              >
                                <div class="container-fluid table-scroll">
                                  <table class="table table-hover table-sm">
                                    <tr
                                      class="text tr-head table-wrapper-scroll-y rounded-circle"
                                    >
                                      <th>+</th>

                                      <th>Name</th>

                                      <th>For</th>
                                      <th>Description</th>
                                      <th>Status</th>
                                      <th class="text-end">
                                        <input
                                          v-if="
                                            prePermissionIDs25.length ==
                                            permissions25.length
                                          "
                                          @click="
                                            allCheclSand(
                                              $event,
                                              permissions25,
                                              'clientservice-certificate'
                                            )
                                          "
                                          type="checkbox"
                                          checked
                                        />
                                        <input
                                          v-else
                                          @click="
                                            allCheclSand(
                                              $event,
                                              permissions25,
                                              'clientservice-certificate'
                                            )
                                          "
                                          type="checkbox"
                                        />
                                      </th>
                                    </tr>

                                    <tbody>
                                      <tr
                                        style="color: #00364f"
                                        v-for="(permission25, index) in permissions25"
                                        :key="index"
                                      >
                                        <td>
                                          {{ index + 1 }}
                                        </td>

                                        <td class="text-truncate">
                                          {{ permission25.permission }}
                                        </td>
                                        <td>{{ permission25.for }}</td>
                                        <td>{{ permission25.description }}</td>
                                        <td>{{ permission25.sts }}</td>

                                        <td class="text-end">
                                          <input
                                            v-if="permission25.sts == 'true'"
                                            @click="
                                              singleCheckedUncheked(
                                                $event,
                                                permission25,
                                                permissions25,
                                                'clientservice-certificate'
                                              )
                                            "
                                            type="checkbox"
                                            checked
                                          />
                                          <input
                                            v-if="permission25.sts == 'false'"
                                            @click="
                                              singleCheckedUncheked(
                                                $event,
                                                permission25,
                                                permissions25,
                                                'clientservice-certificate'
                                              )
                                            "
                                            type="checkbox"
                                          />

                                          <span v-if="!permission25.sts">
                                            <input disabled type="checkbox" />
                                          </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item" data-v-1b249e63="">
                        <h2
                          @click="clientservicedocumentpermission()"
                          class="accordion-header"
                          data-v-1b249e63=""
                        >
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#clientservice-document"
                            aria-expanded="false"
                            aria-controls="clientservice-document"
                            data-v-1b249e63=""
                          >
                            <i
                              data-feather="plus"
                              class="accordian-plus"
                              data-v-1b249e63=""
                            ></i>
                            Clientservice Document
                          </button>
                        </h2>
                        <div
                          id="clientservice-document"
                          class="accordion-collapse collapse"
                          aria-labelledby="clientservice-document"
                          data-bs-parent="#accordionMargin"
                          data-v-1b249e63=""
                        >
                          <div class="accordion-body" data-v-1b249e63="">
                            <div class="row">
                              <div
                                class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                style="margin-top: 7px"
                              >
                                <div class="container-fluid table-scroll">
                                  <table class="table table-hover table-sm">
                                    <tr
                                      class="text tr-head table-wrapper-scroll-y rounded-circle"
                                    >
                                      <th>+</th>

                                      <th>Name</th>

                                      <th>For</th>
                                      <th>Description</th>
                                      <th>Status</th>
                                      <th class="text-end">
                                        <input
                                          v-if="
                                            prePermissionIDs26.length ==
                                            permissions26.length
                                          "
                                          @click="
                                            allCheclSand(
                                              $event,
                                              permissions26,
                                              'clientservice-documentn'
                                            )
                                          "
                                          type="checkbox"
                                          checked
                                        />
                                        <input
                                          v-else
                                          @click="
                                            allCheclSand(
                                              $event,
                                              permissions26,
                                              'clientservice-documentn'
                                            )
                                          "
                                          type="checkbox"
                                        />
                                      </th>
                                    </tr>

                                    <tbody>
                                      <tr
                                        style="color: #00364f"
                                        v-for="(permission26, index) in permissions26"
                                        :key="index"
                                      >
                                        <td>
                                          {{ index + 1 }}
                                        </td>

                                        <td class="text-truncate">
                                          {{ permission26.permission }}
                                        </td>
                                        <td>{{ permission26.for }}</td>
                                        <td>{{ permission26.description }}</td>
                                        <td>{{ permission26.sts }}</td>

                                        <td class="text-end">
                                          <input
                                            v-if="permission26.sts == 'true'"
                                            @click="
                                              singleCheckedUncheked(
                                                $event,
                                                permission26,
                                                permissions26,
                                                'clientservice-document'
                                              )
                                            "
                                            type="checkbox"
                                            checked
                                          />
                                          <input
                                            v-if="permission26.sts == 'false'"
                                            @click="
                                              singleCheckedUncheked(
                                                $event,
                                                permission26,
                                                permissions26,
                                                'clientservice-document'
                                              )
                                            "
                                            type="checkbox"
                                          />

                                          <span v-if="!permission26.sts">
                                            <input disabled type="checkbox" />
                                          </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item" data-v-1b249e63="">
                        <h2
                          @click="clientserviceprofessionalpermission()"
                          class="accordion-header"
                          data-v-1b249e63=""
                        >
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#clientservice-professional"
                            aria-expanded="false"
                            aria-controls="clientservice-professional"
                            data-v-1b249e63=""
                          >
                            <i
                              data-feather="plus"
                              class="accordian-plus"
                              data-v-1b249e63=""
                            ></i>
                            Clientservice Professional
                          </button>
                        </h2>
                        <div
                          id="clientservice-professional"
                          class="accordion-collapse collapse"
                          aria-labelledby="clientservice-professional"
                          data-bs-parent="#accordionMargin"
                          data-v-1b249e63=""
                        >
                          <div class="accordion-body" data-v-1b249e63="">
                            <div class="row">
                              <div
                                class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                style="margin-top: 7px"
                              >
                                <div class="container-fluid table-scroll">
                                  <table class="table table-hover table-sm">
                                    <tr
                                      class="text tr-head table-wrapper-scroll-y rounded-circle"
                                    >
                                      <th>+</th>

                                      <th>Name</th>

                                      <th>For</th>
                                      <th>Description</th>
                                      <th>Status</th>
                                      <th class="text-end">
                                        <input
                                          v-if="
                                            prePermissionIDs27.length ==
                                            permissions27.length
                                          "
                                          @click="
                                            allCheclSand(
                                              $event,
                                              permissions27,
                                              'clientservice-professional'
                                            )
                                          "
                                          type="checkbox"
                                          checked
                                        />
                                        <input
                                          v-else
                                          @click="
                                            allCheclSand(
                                              $event,
                                              permissions27,
                                              'clientservice-professional'
                                            )
                                          "
                                          type="checkbox"
                                        />
                                      </th>
                                    </tr>

                                    <tbody>
                                      <tr
                                        style="color: #00364f"
                                        v-for="(permission27, index) in permissions27"
                                        :key="index"
                                      >
                                        <td>
                                          {{ index + 1 }}
                                        </td>

                                        <td class="text-truncate">
                                          {{ permission27.permission }}
                                        </td>
                                        <td>{{ permission27.for }}</td>
                                        <td>{{ permission27.description }}</td>
                                        <td>{{ permission27.sts }}</td>

                                        <td class="text-end">
                                          <input
                                            v-if="permission27.sts == 'true'"
                                            @click="
                                              singleCheckedUncheked(
                                                $event,
                                                permission27,
                                                permissions27,
                                                'clientservice-professional'
                                              )
                                            "
                                            type="checkbox"
                                            checked
                                          />
                                          <input
                                            v-if="permission27.sts == 'false'"
                                            @click="
                                              singleCheckedUncheked(
                                                $event,
                                                permission27,
                                                permissions27,
                                                'clientservice-professional'
                                              )
                                            "
                                            type="checkbox"
                                          />

                                          <span v-if="!permission27.sts">
                                            <input disabled type="checkbox" />
                                          </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item" data-v-1b249e63="">
                        <h2
                          @click="clientserviceprofessionalsteppermission()"
                          class="accordion-header"
                          data-v-1b249e63=""
                        >
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#clientservice-professional-step"
                            aria-expanded="false"
                            aria-controls="clientservice-professional-step"
                            data-v-1b249e63=""
                          >
                            <i
                              data-feather="plus"
                              class="accordian-plus"
                              data-v-1b249e63=""
                            ></i>
                            Clientservice Professional Step
                          </button>
                        </h2>
                        <div
                          id="clientservice-professional-step"
                          class="accordion-collapse collapse"
                          aria-labelledby="clientservice-professional-step"
                          data-bs-parent="#accordionMargin"
                          data-v-1b249e63=""
                        >
                          <div class="accordion-body" data-v-1b249e63="">
                            <div class="row">
                              <div
                                class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                style="margin-top: 7px"
                              >
                                <div class="container-fluid table-scroll">
                                  <table class="table table-hover table-sm">
                                    <tr
                                      class="text tr-head table-wrapper-scroll-y rounded-circle"
                                    >
                                      <th>+</th>

                                      <th>Name</th>

                                      <th>For</th>
                                      <th>Description</th>
                                      <th>Status</th>
                                      <th class="text-end">
                                        <input
                                          v-if="
                                            prePermissionIDs28.length ==
                                            permissions28.length
                                          "
                                          @click="
                                            allCheclSand(
                                              $event,
                                              permissions28,
                                              'clientservice-professional-step'
                                            )
                                          "
                                          type="checkbox"
                                          checked
                                        />
                                        <input
                                          v-else
                                          @click="
                                            allCheclSand(
                                              $event,
                                              permissions28,
                                              'clientservice-professional-step'
                                            )
                                          "
                                          type="checkbox"
                                        />
                                      </th>
                                    </tr>

                                    <tbody>
                                      <tr
                                        style="color: #00364f"
                                        v-for="(permission28, index) in permissions28"
                                        :key="index"
                                      >
                                        <td>
                                          {{ index + 1 }}
                                        </td>

                                        <td class="text-truncate">
                                          {{ permission28.permission }}
                                        </td>
                                        <td>{{ permission28.for }}</td>
                                        <td>{{ permission28.description }}</td>
                                        <td>{{ permission28.sts }}</td>

                                        <td class="text-end">
                                          <input
                                            v-if="permission28.sts == 'true'"
                                            @click="
                                              singleCheckedUncheked(
                                                $event,
                                                permission28,
                                                permissions28,
                                                'clientservice-professional-step'
                                              )
                                            "
                                            type="checkbox"
                                            checked
                                          />
                                          <input
                                            v-if="permission28.sts == 'false'"
                                            @click="
                                              singleCheckedUncheked(
                                                $event,
                                                permission28,
                                                permissions28,
                                                'clientservice-professional-step'
                                              )
                                            "
                                            type="checkbox"
                                          />

                                          <span v-if="!permission28.sts">
                                            <input disabled type="checkbox" />
                                          </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item" data-v-1b249e63="">
                        <h2
                          @click="clientservicerequestdocpermission()"
                          class="accordion-header"
                          data-v-1b249e63=""
                        >
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#clientservice-request-doc"
                            aria-expanded="false"
                            aria-controls="clientservice-request-doc"
                            data-v-1b249e63=""
                          >
                            <i
                              data-feather="plus"
                              class="accordian-plus"
                              data-v-1b249e63=""
                            ></i>
                            Clientservice Request Documents
                          </button>
                        </h2>
                        <div
                          id="clientservice-request-doc"
                          class="accordion-collapse collapse"
                          aria-labelledby="clientservice-request-doc"
                          data-bs-parent="#accordionMargin"
                          data-v-1b249e63=""
                        >
                          <div class="accordion-body" data-v-1b249e63="">
                            <div class="row">
                              <div
                                class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                style="margin-top: 7px"
                              >
                                <div class="container-fluid table-scroll">
                                  <table class="table table-hover table-sm">
                                    <tr
                                      class="text tr-head table-wrapper-scroll-y rounded-circle"
                                    >
                                      <th>+</th>

                                      <th>Name</th>

                                      <th>For</th>
                                      <th>Description</th>
                                      <th>Status</th>
                                      <th class="text-end">
                                        <input
                                          v-if="
                                            prePermissionIDs29.length ==
                                            permissions29.length
                                          "
                                          @click="
                                            allCheclSand(
                                              $event,
                                              permissions29,
                                              'clientservice-request-doc'
                                            )
                                          "
                                          type="checkbox"
                                          checked
                                        />
                                        <input
                                          v-else
                                          @click="
                                            allCheclSand(
                                              $event,
                                              permissions29,
                                              'clientservice-request-doc'
                                            )
                                          "
                                          type="checkbox"
                                        />
                                      </th>
                                    </tr>

                                    <tbody>
                                      <tr
                                        style="color: #00364f"
                                        v-for="(permission29, index) in permissions29"
                                        :key="index"
                                      >
                                        <td>
                                          {{ index + 1 }}
                                        </td>

                                        <td class="text-truncate">
                                          {{ permission29.permission }}
                                        </td>
                                        <td>{{ permission29.for }}</td>
                                        <td>{{ permission29.description }}</td>
                                        <td>{{ permission29.sts }}</td>

                                        <td class="text-end">
                                          <input
                                            v-if="permission29.sts == 'true'"
                                            @click="
                                              singleCheckedUncheked(
                                                $event,
                                                permission29,
                                                permissions29,
                                                'clientservice-request-doc'
                                              )
                                            "
                                            type="checkbox"
                                            checked
                                          />
                                          <input
                                            v-if="permission29.sts == 'false'"
                                            @click="
                                              singleCheckedUncheked(
                                                $event,
                                                permission29,
                                                permissions29,
                                                'clientservice-request-doc'
                                              )
                                            "
                                            type="checkbox"
                                          />

                                          <span v-if="!permission29.sts">
                                            <input disabled type="checkbox" />
                                          </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item" data-v-1b249e63="">
                        <h2
                          @click="clientserviceticketpermission()"
                          class="accordion-header"
                          data-v-1b249e63=""
                        >
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#clientservice-ticket"
                            aria-expanded="false"
                            aria-controls="clientservice-ticket"
                            data-v-1b249e63=""
                          >
                            <i
                              data-feather="plus"
                              class="accordian-plus"
                              data-v-1b249e63=""
                            ></i>
                            Clientservice ticket
                          </button>
                        </h2>
                        <div
                          id="clientservice-ticket"
                          class="accordion-collapse collapse"
                          aria-labelledby="clientservice-ticket"
                          data-bs-parent="#accordionMargin"
                          data-v-1b249e63=""
                        >
                          <div class="accordion-body" data-v-1b249e63="">
                            <div class="row">
                              <div
                                class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                style="margin-top: 7px"
                              >
                                <div class="container-fluid table-scroll">
                                  <table class="table table-hover table-sm">
                                    <tr
                                      class="text tr-head table-wrapper-scroll-y rounded-circle"
                                    >
                                      <th>+</th>

                                      <th>Name</th>

                                      <th>For</th>
                                      <th>Description</th>
                                      <th>Status</th>
                                      <th class="text-end">
                                        <input
                                          v-if="
                                            prePermissionIDs30.length ==
                                            permissions30.length
                                          "
                                          @click="
                                            allCheclSand(
                                              $event,
                                              permissions30,
                                              'clientservice-ticket'
                                            )
                                          "
                                          type="checkbox"
                                          checked
                                        />
                                        <input
                                          v-else
                                          @click="
                                            allCheclSand(
                                              $event,
                                              permissions30,
                                              'clientservice-ticket'
                                            )
                                          "
                                          type="checkbox"
                                        />
                                      </th>
                                    </tr>

                                    <tbody>
                                      <tr
                                        style="color: #00364f"
                                        v-for="(permission30, index) in permissions30"
                                        :key="index"
                                      >
                                        <td>
                                          {{ index + 1 }}
                                        </td>

                                        <td class="text-truncate">
                                          {{ permission30.permission }}
                                        </td>
                                        <td>{{ permission30.for }}</td>
                                        <td>{{ permission30.description }}</td>
                                        <td>{{ permission30.sts }}</td>

                                        <td class="text-end">
                                          <input
                                            v-if="permission30.sts == 'true'"
                                            @click="
                                              singleCheckedUncheked(
                                                $event,
                                                permission30,
                                                permissions30,
                                                'clientservice-ticket'
                                              )
                                            "
                                            type="checkbox"
                                            checked
                                          />
                                          <input
                                            v-if="permission30.sts == 'false'"
                                            @click="
                                              singleCheckedUncheked(
                                                $event,
                                                permission30,
                                                permissions30,
                                                'clientservice-ticket'
                                              )
                                            "
                                            type="checkbox"
                                          />

                                          <span v-if="!permission30.sts">
                                            <input disabled type="checkbox" />
                                          </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item" data-v-1b249e63="">
                        <h2
                          @click="clientserviceticketdetailpermission()"
                          class="accordion-header"
                          data-v-1b249e63=""
                        >
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#clientservice-ticket-detail"
                            aria-expanded="false"
                            aria-controls="clientservice-ticket-detail"
                            data-v-1b249e63=""
                          >
                            <i
                              data-feather="plus"
                              class="accordian-plus"
                              data-v-1b249e63=""
                            ></i>
                            Clientservice Ticket Detail
                          </button>
                        </h2>
                        <div
                          id="clientservice-ticket-detail"
                          class="accordion-collapse collapse"
                          aria-labelledby="clientservice-ticket-detail"
                          data-bs-parent="#accordionMargin"
                          data-v-1b249e63=""
                        >
                          <div class="accordion-body" data-v-1b249e63="">
                            <div class="row">
                              <div
                                class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                style="margin-top: 7px"
                              >
                                <div class="container-fluid table-scroll">
                                  <table class="table table-hover table-sm">
                                    <tr
                                      class="text tr-head table-wrapper-scroll-y rounded-circle"
                                    >
                                      <th>+</th>

                                      <th>Name</th>

                                      <th>For</th>
                                      <th>Description</th>
                                      <th>Status</th>
                                      <th class="text-end">
                                        <input
                                          v-if="
                                            prePermissionIDs31.length ==
                                            permissions31.length
                                          "
                                          @click="
                                            allCheclSand(
                                              $event,
                                              permissions31,
                                              'clientservice-ticket-detail'
                                            )
                                          "
                                          type="checkbox"
                                          checked
                                        />
                                        <input
                                          v-else
                                          @click="
                                            allCheclSand(
                                              $event,
                                              permissions31,
                                              'clientservice-ticket-detail'
                                            )
                                          "
                                          type="checkbox"
                                        />
                                      </th>
                                    </tr>

                                    <tbody>
                                      <tr
                                        style="color: #00364f"
                                        v-for="(permission31, index) in permissions31"
                                        :key="index"
                                      >
                                        <td>
                                          {{ index + 1 }}
                                        </td>

                                        <td class="text-truncate">
                                          {{ permission31.permission }}
                                        </td>
                                        <td>{{ permission31.for }}</td>
                                        <td>{{ permission31.description }}</td>
                                        <td>{{ permission31.sts }}</td>

                                        <td class="text-end">
                                          <input
                                            v-if="permission31.sts == 'true'"
                                            @click="
                                              singleCheckedUncheked(
                                                $event,
                                                permission31,
                                                permissions31,
                                                'clientservice-ticket-detail'
                                              )
                                            "
                                            type="checkbox"
                                            checked
                                          />
                                          <input
                                            v-if="permission31.sts == 'false'"
                                            @click="
                                              singleCheckedUncheked(
                                                $event,
                                                permission31,
                                                permissions31,
                                                'clientservice-ticket-detail'
                                              )
                                            "
                                            type="checkbox"
                                          />

                                          <span v-if="!permission31.sts">
                                            <input disabled type="checkbox" />
                                          </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item" data-v-1b249e63="">
                        <h2
                          @click="clientserviceticketdocpermission()"
                          class="accordion-header"
                          data-v-1b249e63=""
                        >
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#clientservice-ticket-doc"
                            aria-expanded="false"
                            aria-controls="clientservice-ticket-doc"
                            data-v-1b249e63=""
                          >
                            <i
                              data-feather="plus"
                              class="accordian-plus"
                              data-v-1b249e63=""
                            ></i>
                            Clientservice Ticket Documents
                          </button>
                        </h2>
                        <div
                          id="clientservice-ticket-doc"
                          class="accordion-collapse collapse"
                          aria-labelledby="clientservice-ticket-doc"
                          data-bs-parent="#accordionMargin"
                          data-v-1b249e63=""
                        >
                          <div class="accordion-body" data-v-1b249e63="">
                            <div class="row">
                              <div
                                class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                style="margin-top: 7px"
                              >
                                <div class="container-fluid table-scroll">
                                  <table class="table table-hover table-sm">
                                    <tr
                                      class="text tr-head table-wrapper-scroll-y rounded-circle"
                                    >
                                      <th>+</th>

                                      <th>Name</th>

                                      <th>For</th>
                                      <th>Description</th>
                                      <th>Status</th>
                                      <th class="text-end">
                                        <input
                                          v-if="
                                            prePermissionIDs32.length ==
                                            permissions32.length
                                          "
                                          @click="
                                            allCheclSand(
                                              $event,
                                              permissions32,
                                              'clientservice-ticket'
                                            )
                                          "
                                          type="checkbox"
                                          checked
                                        />
                                        <input
                                          v-else
                                          @click="
                                            allCheclSand(
                                              $event,
                                              permissions32,
                                              'clientservice-ticket'
                                            )
                                          "
                                          type="checkbox"
                                        />
                                      </th>
                                    </tr>

                                    <tbody>
                                      <tr
                                        style="color: #00364f"
                                        v-for="(permission32, index) in permissions32"
                                        :key="index"
                                      >
                                        <td>
                                          {{ index + 1 }}
                                        </td>

                                        <td class="text-truncate">
                                          {{ permission32.permission }}
                                        </td>
                                        <td>{{ permission32.for }}</td>
                                        <td>{{ permission32.description }}</td>
                                        <td>{{ permission32.sts }}</td>

                                        <td class="text-end">
                                          <input
                                            v-if="permission32.sts == 'true'"
                                            @click="
                                              singleCheckedUncheked(
                                                $event,
                                                permission32,
                                                permissions32,
                                                'clientservice-ticket-doc'
                                              )
                                            "
                                            type="checkbox"
                                            checked
                                          />
                                          <input
                                            v-if="permission32.sts == 'false'"
                                            @click="
                                              singleCheckedUncheked(
                                                $event,
                                                permission32,
                                                permissions32,
                                                'clientservice-ticket-doc'
                                              )
                                            "
                                            type="checkbox"
                                          />

                                          <span v-if="!permission32.sts">
                                            <input disabled type="checkbox" />
                                          </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item" data-v-1b249e63="">
                        <h2
                          @click="clientserviceupgradepermission()"
                          class="accordion-header"
                          data-v-1b249e63=""
                        >
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#clientservice-upgrade"
                            aria-expanded="false"
                            aria-controls="clientservice-upgrade"
                            data-v-1b249e63=""
                          >
                            <i
                              data-feather="plus"
                              class="accordian-plus"
                              data-v-1b249e63=""
                            ></i>
                            Clientservice Upgrade
                          </button>
                        </h2>
                        <div
                          id="clientservice-upgrade"
                          class="accordion-collapse collapse"
                          aria-labelledby="clientservice-upgrade"
                          data-bs-parent="#accordionMargin"
                          data-v-1b249e63=""
                        >
                          <div class="accordion-body" data-v-1b249e63="">
                            <div class="row">
                              <div
                                class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                style="margin-top: 7px"
                              >
                                <div class="container-fluid table-scroll">
                                  <table class="table table-hover table-sm">
                                    <tr
                                      class="text tr-head table-wrapper-scroll-y rounded-circle"
                                    >
                                      <th>+</th>

                                      <th>Name</th>

                                      <th>For</th>
                                      <th>Description</th>
                                      <th>Status</th>
                                      <th class="text-end">
                                        <input
                                          v-if="
                                            prePermissionIDs33.length ==
                                            permissions33.length
                                          "
                                          @click="
                                            allCheclSand(
                                              $event,
                                              permissions33,
                                              'clientservice-upgrade'
                                            )
                                          "
                                          type="checkbox"
                                          checked
                                        />
                                        <input
                                          v-else
                                          @click="
                                            allCheclSand(
                                              $event,
                                              permissions33,
                                              'clientservice-upgrade'
                                            )
                                          "
                                          type="checkbox"
                                        />
                                      </th>
                                    </tr>

                                    <tbody>
                                      <tr
                                        style="color: #00364f"
                                        v-for="(permission33, index) in permissions33"
                                        :key="index"
                                      >
                                        <td>
                                          {{ index + 1 }}
                                        </td>

                                        <td class="text-truncate">
                                          {{ permission33.permission }}
                                        </td>
                                        <td>{{ permission33.for }}</td>
                                        <td>{{ permission33.description }}</td>
                                        <td>{{ permission33.sts }}</td>

                                        <td class="text-end">
                                          <input
                                            v-if="permission33.sts == 'true'"
                                            @click="
                                              singleCheckedUncheked(
                                                $event,
                                                permission33,
                                                permissions33,
                                                'clientservice-upgrade'
                                              )
                                            "
                                            type="checkbox"
                                            checked
                                          />
                                          <input
                                            v-if="permission33.sts == 'false'"
                                            @click="
                                              singleCheckedUncheked(
                                                $event,
                                                permission33,
                                                permissions33,
                                                'clientservice-upgrade'
                                              )
                                            "
                                            type="checkbox"
                                          />

                                          <span v-if="!permission33.sts">
                                            <input disabled type="checkbox" />
                                          </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item" data-v-1b249e63="">
                        <h2
                          @click="clientservicestatepermission()"
                          class="accordion-header"
                          data-v-1b249e63=""
                        >
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#state"
                            aria-expanded="false"
                            aria-controls="state"
                            data-v-1b249e63=""
                          >
                            <i
                              data-feather="plus"
                              class="accordian-plus"
                              data-v-1b249e63=""
                            ></i>
                            State
                          </button>
                        </h2>
                        <div
                          id="state"
                          class="accordion-collapse collapse"
                          aria-labelledby="state"
                          data-bs-parent="#accordionMargin"
                          data-v-1b249e63=""
                        >
                          <div class="accordion-body" data-v-1b249e63="">
                            <div class="row">
                              <div
                                class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                style="margin-top: 7px"
                              >
                                <div class="container-fluid table-scroll">
                                  <table class="table table-hover table-sm">
                                    <tr
                                      class="text tr-head table-wrapper-scroll-y rounded-circle"
                                    >
                                      <th>+</th>

                                      <th>Name</th>

                                      <th>For</th>
                                      <th>Description</th>
                                      <th>Status</th>
                                      <th class="text-end">
                                        <input
                                          v-if="
                                            prePermissionIDs34.length ==
                                            permissions34.length
                                          "
                                          @click="
                                            allCheclSand($event, permissions34, 'state')
                                          "
                                          type="checkbox"
                                          checked
                                        />
                                        <input
                                          v-else
                                          @click="
                                            allCheclSand($event, permissions34, 'state')
                                          "
                                          type="checkbox"
                                        />
                                      </th>
                                    </tr>

                                    <tbody>
                                      <tr
                                        style="color: #00364f"
                                        v-for="(permission34, index) in permissions34"
                                        :key="index"
                                      >
                                        <td>
                                          {{ index + 1 }}
                                        </td>

                                        <td class="text-truncate">
                                          {{ permission34.permission }}
                                        </td>
                                        <td>{{ permission34.for }}</td>
                                        <td>{{ permission34.description }}</td>
                                        <td>{{ permission34.sts }}</td>

                                        <td class="text-end">
                                          <input
                                            v-if="permission34.sts == 'true'"
                                            @click="
                                              singleCheckedUncheked(
                                                $event,
                                                permission34,
                                                permissions34,
                                                'state'
                                              )
                                            "
                                            type="checkbox"
                                            checked
                                          />
                                          <input
                                            v-if="permission34.sts == 'false'"
                                            @click="
                                              singleCheckedUncheked(
                                                $event,
                                                permission34,
                                                permissions34,
                                                'state'
                                              )
                                            "
                                            type="checkbox"
                                          />

                                          <span v-if="!permission34.sts">
                                            <input disabled type="checkbox" />
                                          </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Content-->

    <div class="sidenav-overlay"></div>
    <div class="drag-target"></div>
  </div>

  <!-- department model  -->

  <!-- Modal -->
  <div
    class="modal fade"
    id="exampleModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Staff Department</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="edit == 'false' ? create() : update()">
            <div class="form-group" style="padding: 5px 5px 5px 5px">
              <label>Name</label>
              <input
                type="text"
                v-model="form.name"
                class="form-control"
                placeholder="Name"
              />
            </div>

            <div class="form-group" style="padding: 5px 5px 5px 5px">
              <label>Status</label>
              <select
                v-model="form.status"
                class="form-select"
                aria-label="Default select example"
              >
                <option value="true">Active</option>
                <option value="false">InActive</option>
              </select>
            </div>

            <div class="form-group" style="padding: 5px 5px 5px 5px">
              <label>Remark</label>
              <textarea v-model="form.remark" class="form-control" rows="3"></textarea>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-sm text-white"
                style="background-color: #f21000"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="submit"
                class="btn btn-sm text-white"
                style="background-color: #00364f"
              >
                Confirm
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

//
<script>
import Banner from "../../../components/super-admin/comman/Banner.vue";
import Spinner from "../../../components/super-admin/comman/Spinner.vue";
// import Form from "vform";
// import jQuery from "jquery";
// let $ = jQuery;
var _ = require("lodash");
export default {
  name: "User Roles",

  components: {
    Banner,
    Spinner,
  },
  data() {
    return {
      departmentPermissions: [],
      departments: [],
      loading: false,
      permissions: [],
      permissions1: [],
      permissions2: [],
      permissions3: [],
      permissions4: [],
      permissions5: [],
      permissions6: [],
      permissions7: [],
      permissions8: [],
      permissions9: [],
      permissions10: [],
      permissions11: [],
      permissions12: [],
      permissions13: [],
      permissions14: [],
      permissions15: [],
      permissions16: [],
      permissions17: [],
      permissions18: [],
      permissions19: [],
      permissions20: [],
      permissions21: [],
      permissions22: [],
      permissions23: [],
      permissions24: [],
      permissions25: [],
      permissions26: [],
      permissions27: [],
      permissions28: [],
      permissions29: [],

      permissions30: [],
      permissions31: [],
      permissions32: [],
      permissions33: [],
      permissions34: [],
      permissions35: [],
      permissions36: [],
      permissions37: [],
      permissions38: [],
      permissions39: [],
      permissions40: [],

      edit: "false",
      form: {
        name: "",
        remark: "",
        status: "",
      },
      department_id: null,
      permissionsForm: {
        department_id: null,
        section_permissions: [],

        permissions: [],
        status: "",
      },
      prePermissionIDs: [],
      prePermissionIDs1: [],
      prePermissionIDs2: [],
      prePermissionIDs3: [],
      prePermissionIDs4: [],
      prePermissionIDs5: [],
      prePermissionIDs6: [],
      prePermissionIDs7: [],
      prePermissionIDs8: [],
      prePermissionIDs9: [],
      prePermissionIDs10: [],
      prePermissionIDs11: [],
      prePermissionIDs12: [],
      prePermissionIDs13: [],
      prePermissionIDs14: [],
      prePermissionIDs15: [],
      prePermissionIDs16: [],
      prePermissionIDs17: [],
      prePermissionIDs18: [],
      prePermissionIDs19: [],
      prePermissionIDs20: [],
      prePermissionIDs21: [],
      prePermissionIDs22: [],
      prePermissionIDs23: [],
      prePermissionIDs24: [],
      prePermissionIDs25: [],
      prePermissionIDs26: [],
      prePermissionIDs27: [],
      prePermissionIDs28: [],
      prePermissionIDs29: [],
      prePermissionIDs30: [],
      prePermissionIDs31: [],
      prePermissionIDs32: [],
      prePermissionIDs33: [],
      prePermissionIDs34: [],
    };
  },
  methods: {
    loadDepartment() {
      this.loading = true;
      this.$axios
        .get(`superadmin/department?all=all`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log("==============department==============");
          console.log(res.data.data);
          console.log("============================");
          this.departments = res.data.data;
        })
        .finally(() => (this.loading = false));
    },
    departmentDetailsAssin(event) {
      this.department_id = event.target.value;

      this.loadPermissionDepartment();

      this.adminpermission();
    },
    loadPermissionDepartment() {
      this.$axios
        .get(
          `superadmin/departmentpermission?all=all&department_id=${this.department_id}`,
          {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          }
        )
        .then((res) => {
          this.departmentPermissions = res.data.data;
          // ---------------------------------------------------
          var serModule = this.permissions;
          var prePermissionIDs = [];
          _.forEach(this.departmentPermissions, function (value) {
            if (
              _.find(serModule, function (o) {
                return o.id == value.permission_id;
              })
            ) {
              var index = _.findIndex(serModule, function (o) {
                return o.id == value.permission_id;
              });
              serModule[index].sts = value.status;
              if (serModule[index].sts == "true") {
                prePermissionIDs.push(value.permission_id);
              }
            }
          });
          this.permissions = serModule;
          this.prePermissionIDs = prePermissionIDs;

          // ---------------------------------------------------
          var serModule1 = this.permissions1;
          var prePermissionIDs1 = [];
          _.forEach(this.departmentPermissions, function (value) {
            if (
              _.find(serModule1, function (o) {
                return o.id == value.permission_id;
              })
            ) {
              var index = _.findIndex(serModule1, function (o) {
                return o.id == value.permission_id;
              });
              serModule1[index].sts = value.status;
              if (serModule1[index].sts == "true") {
                prePermissionIDs1.push(value.permission_id);
              }
            }
          });
          this.permissions1 = serModule1;
          this.prePermissionIDs1 = prePermissionIDs1;
          // ---------------------------------------------------

          var serModule2 = this.permissions2;
          var prePermissionIDs2 = [];
          _.forEach(this.departmentPermissions, function (value) {
            if (
              _.find(serModule2, function (o) {
                return o.id == value.permission_id;
              })
            ) {
              var index = _.findIndex(serModule2, function (o) {
                return o.id == value.permission_id;
              });
              serModule2[index].sts = value.status;
              if (serModule2[index].sts == "true") {
                prePermissionIDs2.push(value.permission_id);
              }
            }
          });
          this.permissions2 = serModule2;
          this.prePermissionIDs2 = prePermissionIDs2;
          // ---------------------------------------------------

          var serModule3 = this.permissions3;
          var prePermissionIDs3 = [];
          _.forEach(this.departmentPermissions, function (value) {
            if (
              _.find(serModule3, function (o) {
                return o.id == value.permission_id;
              })
            ) {
              var index = _.findIndex(serModule3, function (o) {
                return o.id == value.permission_id;
              });
              serModule3[index].sts = value.status;
              if (serModule3[index].sts == "true") {
                prePermissionIDs3.push(value.permission_id);
              }
            }
          });
          this.permissions3 = serModule3;
          this.prePermissionIDs3 = prePermissionIDs3;
          // ---------------------------------------------------

          var serModule4 = this.permissions4;
          var prePermissionIDs4 = [];
          _.forEach(this.departmentPermissions, function (value) {
            if (
              _.find(serModule4, function (o) {
                return o.id == value.permission_id;
              })
            ) {
              var index = _.findIndex(serModule4, function (o) {
                return o.id == value.permission_id;
              });
              serModule4[index].sts = value.status;
              if (serModule4[index].sts == "true") {
                prePermissionIDs4.push(value.permission_id);
              }
            }
          });
          this.permissions4 = serModule4;
          this.prePermissionIDs4 = prePermissionIDs4;
          // ---------------------------------------------------

          var serModule5 = this.permissions5;
          var prePermissionIDs5 = [];
          _.forEach(this.departmentPermissions, function (value) {
            if (
              _.find(serModule5, function (o) {
                return o.id == value.permission_id;
              })
            ) {
              var index = _.findIndex(serModule5, function (o) {
                return o.id == value.permission_id;
              });
              serModule5[index].sts = value.status;
              if (serModule5[index].sts == "true") {
                prePermissionIDs5.push(value.permission_id);
              }
            }
          });
          this.permissions5 = serModule5;
          this.prePermissionIDs5 = prePermissionIDs5;
          // ---------------------------------------------------

          var serModule6 = this.permissions6;
          var prePermissionIDs6 = [];
          _.forEach(this.departmentPermissions, function (value) {
            if (
              _.find(serModule6, function (o) {
                return o.id == value.permission_id;
              })
            ) {
              var index = _.findIndex(serModule6, function (o) {
                return o.id == value.permission_id;
              });
              serModule6[index].sts = value.status;
              if (serModule6[index].sts == "true") {
                prePermissionIDs6.push(value.permission_id);
              }
            }
          });
          this.permissions6 = serModule6;
          this.prePermissionIDs6 = prePermissionIDs6;
          // ---------------------------------------------------

          var serModule7 = this.permissions7;
          var prePermissionIDs7 = [];
          _.forEach(this.departmentPermissions, function (value) {
            if (
              _.find(serModule7, function (o) {
                return o.id == value.permission_id;
              })
            ) {
              var index = _.findIndex(serModule7, function (o) {
                return o.id == value.permission_id;
              });
              serModule7[index].sts = value.status;
              if (serModule7[index].sts == "true") {
                prePermissionIDs7.push(value.permission_id);
              }
            }
          });
          this.permissions7 = serModule7;
          this.prePermissionIDs7 = prePermissionIDs7;
          // ---------------------------------------------------

          var serModule8 = this.permissions8;
          var prePermissionIDs8 = [];
          _.forEach(this.departmentPermissions, function (value) {
            if (
              _.find(serModule8, function (o) {
                return o.id == value.permission_id;
              })
            ) {
              var index = _.findIndex(serModule8, function (o) {
                return o.id == value.permission_id;
              });
              serModule8[index].sts = value.status;
              if (serModule8[index].sts == "true") {
                prePermissionIDs8.push(value.permission_id);
              }
            }
          });
          this.permissions8 = serModule8;
          this.prePermissionIDs8 = prePermissionIDs8;
          // ---------------------------------------------------

          var serModule9 = this.permissions9;
          var prePermissionIDs9 = [];
          _.forEach(this.departmentPermissions, function (value) {
            if (
              _.find(serModule9, function (o) {
                return o.id == value.permission_id;
              })
            ) {
              var index = _.findIndex(serModule9, function (o) {
                return o.id == value.permission_id;
              });
              serModule9[index].sts = value.status;
              if (serModule9[index].sts == "true") {
                prePermissionIDs9.push(value.permission_id);
              }
            }
          });
          this.permissions9 = serModule9;
          this.prePermissionIDs9 = prePermissionIDs9;
          // ---------------------------------------------------

          var serModule10 = this.permissions10;
          var prePermissionIDs10 = [];
          _.forEach(this.departmentPermissions, function (value) {
            if (
              _.find(serModule10, function (o) {
                return o.id == value.permission_id;
              })
            ) {
              var index = _.findIndex(serModule10, function (o) {
                return o.id == value.permission_id;
              });
              serModule10[index].sts = value.status;
              if (serModule10[index].sts == "true") {
                prePermissionIDs10.push(value.permission_id);
              }
            }
          });
          this.permissions10 = serModule10;
          this.prePermissionIDs10 = prePermissionIDs10;
          // ---------------------------------------------------
          // ---------------------------------------------------

          var serModule11 = this.permissions11;
          var prePermissionIDs11 = [];
          _.forEach(this.departmentPermissions, function (value) {
            if (
              _.find(serModule11, function (o) {
                return o.id == value.permission_id;
              })
            ) {
              var index = _.findIndex(serModule11, function (o) {
                return o.id == value.permission_id;
              });
              serModule11[index].sts = value.status;
              if (serModule11[index].sts == "true") {
                prePermissionIDs11.push(value.permission_id);
              }
            }
          });
          this.permissions11 = serModule11;
          this.prePermissionIDs11 = prePermissionIDs11;
          // ---------------------------------------------------

          var serModule12 = this.permissions12;
          var prePermissionIDs12 = [];
          _.forEach(this.departmentPermissions, function (value) {
            if (
              _.find(serModule12, function (o) {
                return o.id == value.permission_id;
              })
            ) {
              var index = _.findIndex(serModule12, function (o) {
                return o.id == value.permission_id;
              });
              serModule12[index].sts = value.status;
              if (serModule12[index].sts == "true") {
                prePermissionIDs12.push(value.permission_id);
              }
            }
          });

          
          this.permissions12 = serModule12;
          this.prePermissionIDs12 = prePermissionIDs12;
          // ---------------------------------------------------

          var serModule13 = this.permissions13;
          var prePermissionIDs13 = [];
          _.forEach(this.departmentPermissions, function (value) {
            if (
              _.find(serModule13, function (o) {
                return o.id == value.permission_id;
              })
            ) {
              var index = _.findIndex(serModule13, function (o) {
                return o.id == value.permission_id;
              });
              serModule13[index].sts = value.status;
              if (serModule13[index].sts == "true") {
                prePermissionIDs13.push(value.permission_id);
              }
            }
          });
          this.permissions13 = serModule13;
          this.prePermissionIDs13 = prePermissionIDs13;
          // ---------------------------------------------------

          var serModule14 = this.permissions14;
          var prePermissionIDs14 = [];
          _.forEach(this.departmentPermissions, function (value) {
            if (
              _.find(serModule14, function (o) {
                return o.id == value.permission_id;
              })
            ) {
              var index = _.findIndex(serModule14, function (o) {
                return o.id == value.permission_id;
              });
              serModule14[index].sts = value.status;
              if (serModule14[index].sts == "true") {
                prePermissionIDs14.push(value.permission_id);
              }
            }
          });
          this.permissions14 = serModule14;
          this.prePermissionIDs14 = prePermissionIDs14;
          // ---------------------------------------------------

          var serModule15 = this.permissions15;
          var prePermissionIDs15 = [];
          _.forEach(this.departmentPermissions, function (value) {
            if (
              _.find(serModule15, function (o) {
                return o.id == value.permission_id;
              })
            ) {
              var index = _.findIndex(serModule15, function (o) {
                return o.id == value.permission_id;
              });
              serModule15[index].sts = value.status;
              if (serModule15[index].sts == "true") {
                prePermissionIDs15.push(value.permission_id);
              }
            }
          });
          this.permissions15 = serModule15;
          this.prePermissionIDs15 = prePermissionIDs15;
          // ---------------------------------------------------

          var serModule16 = this.permissions16;
          var prePermissionIDs16 = [];
          _.forEach(this.departmentPermissions, function (value) {
            if (
              _.find(serModule16, function (o) {
                return o.id == value.permission_id;
              })
            ) {
              var index = _.findIndex(serModule16, function (o) {
                return o.id == value.permission_id;
              });
              serModule16[index].sts = value.status;
              if (serModule16[index].sts == "true") {
                prePermissionIDs16.push(value.permission_id);
              }
            }
          });
          this.permissions16 = serModule16;
          this.prePermissionIDs16 = prePermissionIDs16;
          // ---------------------------------------------------

          var serModule17 = this.permissions17;
          var prePermissionIDs17 = [];
          _.forEach(this.departmentPermissions, function (value) {
            if (
              _.find(serModule17, function (o) {
                return o.id == value.permission_id;
              })
            ) {
              var index = _.findIndex(serModule17, function (o) {
                return o.id == value.permission_id;
              });
              serModule17[index].sts = value.status;
              if (serModule17[index].sts == "true") {
                prePermissionIDs17.push(value.permission_id);
              }
            }
          });
          this.permissions17 = serModule17;
          this.prePermissionIDs17 = prePermissionIDs17;
          // ---------------------------------------------------

          var serModule18 = this.permissions18;
          var prePermissionIDs18 = [];
          _.forEach(this.departmentPermissions, function (value) {
            if (
              _.find(serModule18, function (o) {
                return o.id == value.permission_id;
              })
            ) {
              var index = _.findIndex(serModule18, function (o) {
                return o.id == value.permission_id;
              });
              serModule18[index].sts = value.status;
              if (serModule18[index].sts == "true") {
                prePermissionIDs18.push(value.permission_id);
              }
            }
          });
          this.permissions18 = serModule18;
          this.prePermissionIDs18 = prePermissionIDs18;
          // ---------------------------------------------------

          var serModule19 = this.permissions19;
          var prePermissionIDs19 = [];
          _.forEach(this.departmentPermissions, function (value) {
            if (
              _.find(serModule19, function (o) {
                return o.id == value.permission_id;
              })
            ) {
              var index = _.findIndex(serModule19, function (o) {
                return o.id == value.permission_id;
              });
              serModule19[index].sts = value.status;
              if (serModule19[index].sts == "true") {
                prePermissionIDs19.push(value.permission_id);
              }
            }
          });
          this.permissions19 = serModule19;
          this.prePermissionIDs19 = prePermissionIDs19;
          // ---------------------------------------------------

          var serModule20 = this.permissions20;
          var prePermissionIDs20 = [];
          _.forEach(this.departmentPermissions, function (value) {
            if (
              _.find(serModule20, function (o) {
                return o.id == value.permission_id;
              })
            ) {
              var index = _.findIndex(serModule20, function (o) {
                return o.id == value.permission_id;
              });
              serModule20[index].sts = value.status;
              if (serModule20[index].sts == "true") {
                prePermissionIDs20.push(value.permission_id);
              }
            }
          });
          this.permissions20 = serModule20;
          this.prePermissionIDs20 = prePermissionIDs20;
          // ---------------------------------------------------

          var serModule21 = this.permissions21;
          var prePermissionIDs21 = [];
          _.forEach(this.departmentPermissions, function (value) {
            if (
              _.find(serModule21, function (o) {
                return o.id == value.permission_id;
              })
            ) {
              var index = _.findIndex(serModule21, function (o) {
                return o.id == value.permission_id;
              });
              serModule21[index].sts = value.status;
              if (serModule21[index].sts == "true") {
                prePermissionIDs21.push(value.id);
              }
            }
          });
          this.permissions21 = serModule21;
          this.prePermissionIDs21 = prePermissionIDs21;
          // ---------------------------------------------------

          var serModule22 = this.permissions22;
          var prePermissionIDs22 = [];
          _.forEach(this.departmentPermissions, function (value) {
            if (
              _.find(serModule22, function (o) {
                return o.id == value.permission_id;
              })
            ) {
              var index = _.findIndex(serModule22, function (o) {
                return o.id == value.permission_id;
              });
              serModule22[index].sts = value.status;
              if (serModule22[index].sts == "true") {
                prePermissionIDs22.push(value.permission_id);
              }
            }
          });
          this.permissions22 = serModule22;
          this.prePermissionIDs22 = prePermissionIDs22;
          // ---------------------------------------------------

          var serModule23 = this.permissions23;
          var prePermissionIDs23 = [];
          _.forEach(this.departmentPermissions, function (value) {
            if (
              _.find(serModule23, function (o) {
                return o.id == value.permission_id;
              })
            ) {
              var index = _.findIndex(serModule23, function (o) {
                return o.id == value.permission_id;
              });
              serModule23[index].sts = value.status;
              if (serModule23[index].sts == "true") {
                prePermissionIDs23.push(value.permission_id);
              }
            }
          });
          this.permissions23 = serModule23;
          this.prePermissionIDs23 = prePermissionIDs23;
          // ---------------------------------------------------

          var serModule24 = this.permissions24;
          var prePermissionIDs24 = [];
          _.forEach(this.departmentPermissions, function (value) {
            if (
              _.find(serModule24, function (o) {
                return o.id == value.permission_id;
              })
            ) {
              var index = _.findIndex(serModule24, function (o) {
                return o.id == value.permission_id;
              });
              serModule24[index].sts = value.status;
              if (serModule24[index].sts == "true") {
                prePermissionIDs24.push(value.permission_id);
              }
            }
          });
          this.permissions24 = serModule24;
          this.prePermissionIDs24 = prePermissionIDs24;
          // ---------------------------------------------------

          var serModule25 = this.permissions25;
          var prePermissionIDs25 = [];
          _.forEach(this.departmentPermissions, function (value) {
            if (
              _.find(serModule25, function (o) {
                return o.id == value.permission_id;
              })
            ) {
              var index = _.findIndex(serModule25, function (o) {
                return o.id == value.permission_id;
              });
              serModule25[index].sts = value.status;
              if (serModule25[index].sts == "true") {
                prePermissionIDs25.push(value.permission_id);
              }
            }
          });
          this.permissions25 = serModule25;
          this.prePermissionIDs25 = prePermissionIDs25;
          // ---------------------------------------------------

          var serModule26 = this.permissions26;
          var prePermissionIDs26 = [];
          _.forEach(this.departmentPermissions, function (value) {
            if (
              _.find(serModule26, function (o) {
                return o.id == value.permission_id;
              })
            ) {
              var index = _.findIndex(serModule26, function (o) {
                return o.id == value.permission_id;
              });
              serModule26[index].sts = value.status;
              if (serModule26[index].sts == "true") {
                prePermissionIDs26.push(value.permission_id);
              }
            }
          });
          this.permissions26 = serModule26;
          this.prePermissionIDs26 = prePermissionIDs26;
          // ---------------------------------------------------

          var serModule27 = this.permissions27;
          var prePermissionIDs27 = [];
          _.forEach(this.departmentPermissions, function (value) {
            if (
              _.find(serModule27, function (o) {
                return o.id == value.permission_id;
              })
            ) {
              var index = _.findIndex(serModule27, function (o) {
                return o.id == value.permission_id;
              });
              serModule27[index].sts = value.status;
              if (serModule27[index].sts == "true") {
                prePermissionIDs27.push(value.permission_id);
              }
            }
          });
          this.permissions27 = serModule27;
          this.prePermissionIDs27 = prePermissionIDs27;
          // ---------------------------------------------------

          var serModule28 = this.permissions28;
          var prePermissionIDs28 = [];
          _.forEach(this.departmentPermissions, function (value) {
            if (
              _.find(serModule28, function (o) {
                return o.id == value.permission_id;
              })
            ) {
              var index = _.findIndex(serModule28, function (o) {
                return o.id == value.permission_id;
              });
              serModule28[index].sts = value.status;
              if (serModule28[index].sts == "true") {
                prePermissionIDs28.push(value.permission_id);
              }
            }
          });
          this.permissions28 = serModule28;
          this.prePermissionIDs28 = prePermissionIDs28;
          // ---------------------------------------------------

          var serModule29 = this.permissions29;
          var prePermissionIDs29 = [];
          _.forEach(this.departmentPermissions, function (value) {
            if (
              _.find(serModule29, function (o) {
                return o.id == value.permission_id;
              })
            ) {
              var index = _.findIndex(serModule29, function (o) {
                return o.id == value.permission_id;
              });
              serModule29[index].sts = value.status;
              if (serModule29[index].sts == "true") {
                prePermissionIDs29.push(value.permission_id);
              }
            }
          });
          this.permissions29 = serModule29;
          this.prePermissionIDs29 = prePermissionIDs29;
          // ---------------------------------------------------

          var serModule30 = this.permissions30;
          var prePermissionIDs30 = [];
          _.forEach(this.departmentPermissions, function (value) {
            if (
              _.find(serModule30, function (o) {
                return o.id == value.permission_id;
              })
            ) {
              var index = _.findIndex(serModule30, function (o) {
                return o.id == value.permission_id;
              });
              serModule30[index].sts = value.status;
              if (serModule30[index].sts == "true") {
                prePermissionIDs30.push(value.permission_id);
              }
            }
          });
          this.permissions30 = serModule30;
          this.prePermissionIDs30 = prePermissionIDs30;
          // ---------------------------------------------------

          var serModule31 = this.permissions31;
          var prePermissionIDs31 = [];
          _.forEach(this.departmentPermissions, function (value) {
            if (
              _.find(serModule31, function (o) {
                return o.id == value.permission_id;
              })
            ) {
              var index = _.findIndex(serModule31, function (o) {
                return o.id == value.permission_id;
              });
              serModule31[index].sts = value.status;
              if (serModule31[index].sts == "true") {
                prePermissionIDs31.push(value.permission_id);
              }
            }
          });
          this.permissions31 = serModule31;
          this.prePermissionIDs31 = prePermissionIDs31;
          // ---------------------------------------------------

          var serModule32 = this.permissions32;
          var prePermissionIDs32 = [];
          _.forEach(this.departmentPermissions, function (value) {
            if (
              _.find(serModule32, function (o) {
                return o.id == value.permission_id;
              })
            ) {
              var index = _.findIndex(serModule32, function (o) {
                return o.id == value.permission_id;
              });
              serModule32[index].sts = value.status;
              if (serModule32[index].sts == "true") {
                prePermissionIDs32.push(value.permission_id);
              }
            }
          });
          this.permissions32 = serModule32;
          this.prePermissionIDs32 = prePermissionIDs32;
          // ---------------------------------------------------

          var serModule33 = this.permissions33;
          var prePermissionIDs33 = [];
          _.forEach(this.departmentPermissions, function (value) {
            if (
              _.find(serModule33, function (o) {
                return o.id == value.permission_id;
              })
            ) {
              var index = _.findIndex(serModule33, function (o) {
                return o.id == value.permission_id;
              });
              serModule33[index].sts = value.status;
              if (serModule33[index].sts == "true") {
                prePermissionIDs33.push(value.permission_id);
              }
            }
          });
          this.permissions33 = serModule33;
          this.prePermissionIDs33 = prePermissionIDs33;
          // ---------------------------------------------------

          var serModule34 = this.permissions34;
          var prePermissionIDs34 = [];
          _.forEach(this.departmentPermissions, function (value) {
            if (
              _.find(serModule34, function (o) {
                return o.id == value.permission_id;
              })
            ) {
              var index = _.findIndex(serModule34, function (o) {
                return o.id == value.permission_id;
              });
              serModule34[index].sts = value.status;
              if (serModule34[index].sts == "true") {
                prePermissionIDs34.push(value.permission_id);
              }
            }
          });
          this.permissions34 = serModule34;
          this.prePermissionIDs34 = prePermissionIDs34;
          // ---------------------------------------------------
        });
    },
    allCheclSand(event, permissions, type) {
      if (type == "admin") {
        this.permissionsForm.permissions = this.prePermissionIDs;
      }
      if (type == "admin-kyc") {
        this.permissionsForm.permissions = this.prePermissionIDs1;
      }
      if (type == "client") {
        this.permissionsForm.permissions = this.prePermissionIDs2;
      }
      if (type == "client-kyc") {
        this.permissionsForm.permissions = this.prePermissionIDs3;
      }
      if (type == "distributer") {
        this.permissionsForm.permissions = this.prePermissionIDs4;
      }
      if (type == "distributer-kyc") {
        this.permissionsForm.permissions = this.prePermissionIDs5;
      }
      if (type == "distributer-wallet") {
        this.permissionsForm.permissions = this.prePermissionIDs6;
      }
      if (type == "master-distributer") {
        this.permissionsForm.permissions = this.prePermissionIDs7;
      }
      if (type == "distributer-beneficiary-account") {
        this.permissionsForm.permissions = this.prePermissionIDs8;
      }
      if (type == "master-distributer-kyc") {
        this.permissionsForm.permissions = this.prePermissionIDs9;
      }
      if (type == "master-distributer-wallet") {
        this.permissionsForm.permissions = this.prePermissionIDs10;
      }
      if (type == "master-distributer-beneficiary-account") {
        this.permissionsForm.permissions = this.prePermissionIDs11;
      }
      if (type == "professional") {
        this.permissionsForm.permissions = this.prePermissionIDs12;
      }
      if (type == "professional-kyc") {
        this.permissionsForm.permissions = this.prePermissionIDs13;
      }
      if (type == "professional-beneficiary-account") {
        this.permissionsForm.permissions = this.prePermissionIDs14;
      }
      if (type == "professional-type") {
        this.permissionsForm.permissions = this.prePermissionIDs15;
      }
      if (type == "retailer") {
        this.permissionsForm.permissions = this.prePermissionIDs16;
      }
      if (type == "	retailer-kyc") {
        this.permissionsForm.permissions = this.prePermissionIDs17;
      }
      if (type == "retailer-wallet") {
        this.permissionsForm.permissions = this.prePermissionIDs18;
      }
      if (type == "retailer-beneficiary-account") {
        this.permissionsForm.permissions = this.prePermissionIDs19;
      }
      if (type == "staff") {
        this.permissionsForm.permissions = this.prePermissionIDs20;
      }
      if (type == "staff-kyc") {
        this.permissionsForm.permissions = this.prePermissionIDs21;
      }
      if (type == "	clientservice") {
        this.permissionsForm.permissions = this.prePermissionIDs22;
      }
      if (type == "clientservice-challan") {
        this.permissionsForm.permissions = this.prePermissionIDs23;
      }
      if (type == "clientservice-comment") {
        this.permissionsForm.permissions = this.prePermissionIDs24;
      }
      if (type == "clientservice-certificate") {
        this.permissionsForm.permissions = this.prePermissionIDs25;
      }
      if (type == "clientservice-document") {
        this.permissionsForm.permissions = this.prePermissionIDs26;
      }
      if (type == "clientservice-professional") {
        this.permissionsForm.permissions = this.prePermissionIDs27;
      }
      if (type == "	clientservice-professional-step") {
        this.permissionsForm.permissions = this.prePermissionIDs28;
      }
      if (type == "clientservice-request-doc") {
        this.permissionsForm.permissions = this.prePermissionIDs29;
      }

      if (type == "clientservice-ticket") {
        this.permissionsForm.permissions = this.prePermissionIDs30;
      }
      if (type == "clientservice-ticket-detail") {
        this.permissionsForm.permissions = this.prePermissionIDs31;
      }
      if (type == "clientservice-ticket-doc") {
        this.permissionsForm.permissions = this.prePermissionIDs32;
      }
      if (type == "clientservice-upgrade") {
        this.permissionsForm.permissions = this.prePermissionIDs33;
      }
      if (type == "state") {
        this.permissionsForm.permissions = this.prePermissionIDs34;
      }
      this.permissionsForm.department_id = this.department_id;
      var selections = [];
      if (event.target.checked == true) {
        for (var row = 0; row < permissions.length; row++) {
          this.permissionsForm.permissions.push(permissions[row].id);
          selections.push(permissions[row].id);
        }
      } else {
        for (var row1 = 0; row1 < permissions.length; row1++) {
          selections.push(permissions[row1].id);
          this.permissionsForm.permissions.splice(
            this.permissionsForm.permissions.indexOf(permissions[row1].id),
            1
          );
        }
      }
      this.permissionsForm.section_permissions = selections;
      this.assignPermission();
    },
    singleCheckedUncheked(event, permission, permissions, type) {
      console.log('=======================single ==========================')
      console.log(permission)
      console.log('================mul=================================')
      console.log(permissions)
      if (type == "admin") {
        this.permissionsForm.permissions = this.prePermissionIDs;
      }
      if (type == "admin-kyc") {
        this.permissionsForm.permissions = this.prePermissionIDs1;
      }
      if (type == "client") {
        this.permissionsForm.permissions = this.prePermissionIDs2;
      }
      if (type == "client-kyc") {
        this.permissionsForm.permissions = this.prePermissionIDs3;
      }
      if (type == "distributer") {
        this.permissionsForm.permissions = this.prePermissionIDs4;
      }
      if (type == "distributer-kyc") {
        this.permissionsForm.permissions = this.prePermissionIDs5;
      }
      if (type == "distributer-wallet") {
        this.permissionsForm.permissions = this.prePermissionIDs6;
      }
      if (type == "master-distributer") {
        this.permissionsForm.permissions = this.prePermissionIDs7;
      }
      if (type == "distributer-beneficiary-account") {
        this.permissionsForm.permissions = this.prePermissionIDs8;
      }
      if (type == "master-distributer-kyc") {
        this.permissionsForm.permissions = this.prePermissionIDs9;
      }
      if (type == "master-distributer-wallet") {
        this.permissionsForm.permissions = this.prePermissionIDs10;
      }
      if (type == "master-distributer-beneficiary-account") {
        this.permissionsForm.permissions = this.prePermissionIDs11;
      }
      if (type == "professional") {
        this.permissionsForm.permissions = this.prePermissionIDs12;
      }
      if (type == "professional-kyc") {
        this.permissionsForm.permissions = this.prePermissionIDs13;
      }
      if (type == "professional-beneficiary-account") {
        this.permissionsForm.permissions = this.prePermissionIDs14;
      }
      if (type == "professional-type") {
        this.permissionsForm.permissions = this.prePermissionIDs15;
      }
      if (type == "retailer") {
        this.permissionsForm.permissions = this.prePermissionIDs16;
      }
      if (type == "	retailer-kyc") {
        this.permissionsForm.permissions = this.prePermissionIDs17;
      }
      if (type == "retailer-wallet") {
        this.permissionsForm.permissions = this.prePermissionIDs18;
      }
      if (type == "retailer-beneficiary-account") {
        this.permissionsForm.permissions = this.prePermissionIDs19;
      }
      if (type == "staff") {
        this.permissionsForm.permissions = this.prePermissionIDs20;
      }
      if (type == "staff-kyc") {
        this.permissionsForm.permissions = this.prePermissionIDs21;
      }
      if (type == "	clientservice") {
        this.permissionsForm.permissions = this.prePermissionIDs22;
      }
      if (type == "clientservice-challan") {
        this.permissionsForm.permissions = this.prePermissionIDs23;
      }
      if (type == "clientservice-comment") {
        this.permissionsForm.permissions = this.prePermissionIDs24;
      }
      if (type == "clientservice-certificate") {
        this.permissionsForm.permissions = this.prePermissionIDs25;
      }
      if (type == "clientservice-document") {
        this.permissionsForm.permissions = this.prePermissionIDs26;
      }
      if (type == "clientservice-professional") {
        this.permissionsForm.permissions = this.prePermissionIDs27;
      }
      if (type == "	clientservice-professional-step") {
        this.permissionsForm.permissions = this.prePermissionIDs28;
      }
      if (type == "clientservice-request-doc") {
        this.permissionsForm.permissions = this.prePermissionIDs29;
      }

      if (type == "clientservice-ticket") {
        this.permissionsForm.permissions = this.prePermissionIDs30;
      }
      if (type == "clientservice-ticket-detail") {
        this.permissionsForm.permissions = this.prePermissionIDs31;
      }
      if (type == "clientservice-ticket-doc") {
        this.permissionsForm.permissions = this.prePermissionIDs32;
      }
      if (type == "clientservice-upgrade") {
        this.permissionsForm.permissions = this.prePermissionIDs33;
      }
      if (type == "state") {
        this.permissionsForm.permissions = this.prePermissionIDs34;
      }

      var arr = [];
      for (let i = 0; i < permissions.length; i++) {
        arr.push(permissions[i].id);
      }

      this.permissionsForm.section_permissions = arr;
      console.log(event.target.checked);

      this.permissionsForm.department_id = this.department_id;

      if (event.target.checked == true) {
        if (permission.permission == `${type}-view`) {
          for (var row = 0; row < permissions.length; row++) {
            if (permissions[row].permission == `${type}-view-any`) {
              this.permissionsForm.permissions.push(permissions[row].id);
            }
          }
        }

        if (
          permission.permission == `${type}-create` ||
          permission.permission == `${type}-update`
        ) {
          for (var row1 = 0; row1 < permissions.length; row1++) {
            if (permissions[row1].permission == `${type}-view-any`) {
              this.permissionsForm.permissions.push(permissions[row1].id);
            }
            if (permissions[row1].permission == `${type}-view`) {
              this.permissionsForm.permissions.push(permissions[row1].id);
            }
          }
        }

        this.permissionsForm.permissions.push(permission.id);
        this.permissionsForm.status = "true";
      } else {
        this.permissionsForm.status = "false";
        this.permissionsForm.permissions.splice(
          this.permissionsForm.permissions.indexOf(permission.id),
          1
        );
      }

      this.assignPermission();
    },

    assignPermission() {
      this.loading = true;
      this.$axios
        .post("superadmin/departmentpermission", this.permissionsForm, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data.data);
          this.loadPermissionDepartment();
        })
        .finally(() => (this.loading = false));
    },
    loadAdminPermission() {
      this.$axios
        .get("superadmin/permission?for=admin", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data.data);
          this.permissions = res.data.data.data;
        });
    },
    adminpermission() {
      if (this.department_id != null && this.permissions.length > 0) {
        this.loadAdminPermission();
        this.loadPermissionDepartment();
      } else if (this.department_id != null && this.permissions.length == 0) {
        this.loadAdminPermission();
        this.loadPermissionDepartment();
      } else {
        this.loadAdminPermission();
      }
    },
    loadAdminKycPermission() {
      this.$axios
        .get("superadmin/permission?for=admin-kyc", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data.data);
          this.permissions1 = res.data.data.data;
        });
    },
    adminkycpermission() {
      if (this.department_id != null && this.permissions.length > 0) {
        this.loadAdminKycPermission();
        this.loadPermissionDepartment();
      } else if (this.department_id != null && this.permissions.length == 0) {
        this.loadAdminKycPermission();
        this.loadPermissionDepartment();
      } else {
        this.loadAdminKycPermission();
      }
    },
    loadClientPermission() {
      this.$axios
        .get("superadmin/permission?for=client", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data.data);
          this.permissions2 = res.data.data.data;
        });
    },
    clientpermission() {
      if (this.department_id != null && this.permissions.length > 0) {
        this.loadClientPermission();
        this.loadPermissionDepartment();
      } else if (this.department_id != null && this.permissions.length == 0) {
        this.loadClientPermission();
        this.loadPermissionDepartment();
      } else {
        this.loadClientPermission();
      }
    },
    loadClientKycPermission() {
      this.$axios
        .get("superadmin/permission?for=client-kyc", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data.data);
          this.permissions3 = res.data.data.data;
        });
    },
    clientkycpermission() {
      if (this.department_id != null && this.permissions.length > 0) {
        this.loadClientKycPermission();
        this.loadPermissionDepartment();
      } else if (this.department_id != null && this.permissions.length == 0) {
        this.loadClientKycPermission();
        this.loadPermissionDepartment();
      } else {
        this.loadClientKycPermission();
      }
    },
    loadDistributerPermission() {
      this.$axios
        .get("superadmin/permission?for=distributer", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data.data);
          this.permissions4 = res.data.data.data;
        });
    },
    distributerpermission() {
      if (this.department_id != null && this.permissions.length > 0) {
        this.loadDistributerPermission();
        this.loadPermissionDepartment();
      } else if (this.department_id != null && this.permissions.length == 0) {
        this.loadDistributerPermission();
        this.loadPermissionDepartment();
      } else {
        this.loadDistributerPermission();
      }
    },
    loadDistributerKycPermission() {
      this.$axios
        .get("superadmin/permission?for=distributer-kyc", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data.data);
          this.permissions5 = res.data.data.data;
        });
    },
    distributerkycpermission() {
      if (this.department_id != null && this.permissions.length > 0) {
        this.loadDistributerKycPermission();
        this.loadPermissionDepartment();
      } else if (this.department_id != null && this.permissions.length == 0) {
        this.loadDistributerKycPermission();
        this.loadPermissionDepartment();
      } else {
        this.loadDistributerKycPermission();
      }
    },
    loadDistributerWalletPermission() {
      this.$axios
        .get("superadmin/permission?for=distributer-wallet", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data.data);
          this.permissions6 = res.data.data.data;
        });
    },
    distributerwalletpermission() {
      if (this.department_id != null && this.permissions.length > 0) {
        this.loadDistributerWalletPermission();
        this.loadPermissionDepartment();
      } else if (this.department_id != null && this.permissions.length == 0) {
        this.loadDistributerWalletPermission();
        this.loadPermissionDepartment();
      } else {
        this.loadDistributerWalletPermission();
      }
    },
    loadMasterDistributerPermission() {
      this.$axios
        .get("superadmin/permission?for=master-distributer", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data.data);
          this.permissions7 = res.data.data.data;
        });
    },
    masterdistributerpermission() {
      if (this.department_id != null && this.permissions.length > 0) {
        this.loadMasterDistributerPermission();
        this.loadPermissionDepartment();
      } else if (this.department_id != null && this.permissions.length == 0) {
        this.loadMasterDistributerPermission();
        this.loadPermissionDepartment();
      } else {
        this.loadMasterDistributerPermission();
      }
    },
    loadDistributerBeneficiaryPermission() {
      this.$axios
        .get("superadmin/permission?for=distributer-beneficiary-account", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data.data);
          this.permissions8 = res.data.data.data;
        });
    },
    distributerbeneficiarypermission() {
      if (this.department_id != null && this.permissions.length > 0) {
        this.loadDistributerBeneficiaryPermission();
        this.loadPermissionDepartment();
      } else if (this.department_id != null && this.permissions.length == 0) {
        this.loadDistributerBeneficiaryPermission();
        this.loadPermissionDepartment();
      } else {
        this.loadDistributerBeneficiaryPermission();
      }
    },
    loadMasterDistributerKycPermission() {
      this.$axios
        .get("superadmin/permission?for=master-distributer-kyc", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data.data);
          this.permissions9 = res.data.data.data;
        });
    },
    masterdistributerkycpermission() {
      if (this.department_id != null && this.permissions.length > 0) {
        this.loadMasterDistributerKycPermission();
        this.loadPermissionDepartment();
      } else if (this.department_id != null && this.permissions.length == 0) {
        this.loadMasterDistributerKycPermission();
        this.loadPermissionDepartment();
      } else {
        this.loadMasterDistributerKycPermission();
      }
    },
    loadMasterDistributerWalletPermission() {
      this.$axios
        .get("superadmin/permission?for=master-distributer-wallet", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data.data);
          this.permissions10 = res.data.data.data;
        });
    },
    masterdistributerwalletpermission() {
      if (this.department_id != null && this.permissions.length > 0) {
        this.loadMasterDistributerWalletPermission();
        this.loadPermissionDepartment();
      } else if (this.department_id != null && this.permissions.length == 0) {
        this.loadMasterDistributerWalletPermission();
        this.loadPermissionDepartment();
      } else {
        this.loadMasterDistributerWalletPermission();
      }
    },
    loadMasterDistributerBeneficiaryPermission() {
      this.$axios
        .get("superadmin/permission?for=master-distributer-beneficiary-account", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data.data);
          this.permissions11 = res.data.data.data;
        });
    },
    masterdistributerbeneficiarypermission() {
      if (this.department_id != null && this.permissions.length > 0) {
        this.loadMasterDistributerBeneficiaryPermission();
        this.loadPermissionDepartment();
      } else if (this.department_id != null && this.permissions.length == 0) {
        this.loadMasterDistributerBeneficiaryPermission();
        this.loadPermissionDepartment();
      } else {
        this.loadMasterDistributerBeneficiaryPermission();
      }
    },
    loadProfessionalPermission() {
      this.$axios
        .get("superadmin/permission?for=professional", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data.data);
          this.permissions12 = res.data.data.data;
        });
    },

    professionalpermission() {
      if (this.department_id != null && this.permissions.length > 0) {
        this.loadProfessionalPermission();
        this.loadPermissionDepartment();
      } else if (this.department_id != null && this.permissions.length == 0) {
        this.loadProfessionalPermission();
        this.loadPermissionDepartment();
      } else {
        this.loadProfessionalPermission();
      }
    },
    loadProfessionalKycPermission() {
      this.$axios
        .get("superadmin/permission?for=professional-kyc", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data.data);
          this.permissions13 = res.data.data.data;
        });
    },
    professionalkycpermission() {
      if (this.department_id != null && this.permissions.length > 0) {
        this.loadProfessionalKycPermission();
        this.loadPermissionDepartment();
      } else if (this.department_id != null && this.permissions.length == 0) {
        this.loadProfessionalKycPermission();
        this.loadPermissionDepartment();
      } else {
        this.loadProfessionalKycPermission();
      }
    },
    loadProfessionalBeneficiaryPermission() {
      this.$axios
        .get("superadmin/permission?for=professional-beneficiary-account", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data.data);
          this.permissions14 = res.data.data.data;
        });
    },
    professionalbeneficiarypermission() {
      if (this.department_id != null && this.permissions.length > 0) {
        this.loadProfessionalBeneficiaryPermission();
        this.loadPermissionDepartment();
      } else if (this.department_id != null && this.permissions.length == 0) {
        this.loadProfessionalBeneficiaryPermission();
        this.loadPermissionDepartment();
      } else {
        this.loadProfessionalBeneficiaryPermission();
      }
    },

    loadProfessionalTypePermission() {
      this.$axios
        .get("superadmin/permission?for=professional-type", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data.data);
          this.permissions15 = res.data.data.data;
        });
    },
    professionaltypepermission() {
      if (this.department_id != null && this.permissions.length > 0) {
        this.loadProfessionalTypePermission();
        this.loadPermissionDepartment();
      } else if (this.department_id != null && this.permissions.length == 0) {
        this.loadProfessionalTypePermission();
        this.loadPermissionDepartment();
      } else {
        this.loadProfessionalTypePermission();
      }
    },
    loadRetailerPermission() {
      this.$axios
        .get("superadmin/permission?for=retailer", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data.data);
          this.permissions16 = res.data.data.data;
        });
    },
    retailerpermission() {
      if (this.department_id != null && this.permissions.length > 0) {
        this.loadRetailerPermission();
        this.loadPermissionDepartment();
      } else if (this.department_id != null && this.permissions.length == 0) {
        this.loadRetailerPermission();
        this.loadPermissionDepartment();
      } else {
        this.loadRetailerPermission();
      }
    },
    loadRetailerKycPermission() {
      this.$axios
        .get("superadmin/permission?for=retailer-kyc", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data.data);
          this.permissions17 = res.data.data.data;
        });
    },
    retailerkycpermission() {
      if (this.department_id != null && this.permissions.length > 0) {
        this.loadRetailerKycPermission();
        this.loadPermissionDepartment();
      } else if (this.department_id != null && this.permissions.length == 0) {
        this.loadRetailerKycPermission();
        this.loadPermissionDepartment();
      } else {
        this.loadRetailerKycPermission();
      }
    },
    loadRetailerWalletPermission() {
      this.$axios
        .get("superadmin/permission?for=retailer-wallet", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data.data);
          this.permissions18 = res.data.data.data;
        });
    },
    retailerwalletpermission() {
      if (this.department_id != null && this.permissions.length > 0) {
        this.loadRetailerWalletPermission();
        this.loadPermissionDepartment();
      } else if (this.department_id != null && this.permissions.length == 0) {
        this.loadRetailerWalletPermission();
        this.loadPermissionDepartment();
      } else {
        this.loadRetailerWalletPermission();
      }
    },

    loadRetailerBeneficiaryPermission() {
      this.$axios
        .get("superadmin/permission?for=retailer-beneficiary-account", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data.data);
          this.permissions19 = res.data.data.data;
        });
    },
    retailerbeneficiarypermission() {
      if (this.department_id != null && this.permissions.length > 0) {
        this.loadRetailerBeneficiaryPermission();
        this.loadPermissionDepartment();
      } else if (this.department_id != null && this.permissions.length == 0) {
        this.loadRetailerBeneficiaryPermission();
        this.loadPermissionDepartment();
      } else {
        this.loadRetailerBeneficiaryPermission();
      }
    },
    loadStaffPermission() {
      this.$axios
        .get("superadmin/permission?for=staff", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data.data);
          this.permissions20 = res.data.data.data;
        });
    },
    staffpermission() {
      if (this.department_id != null && this.permissions.length > 0) {
        this.loadStaffPermission();
        this.loadPermissionDepartment();
      } else if (this.department_id != null && this.permissions.length == 0) {
        this.loadStaffPermission();
        this.loadPermissionDepartment();
      } else {
        this.loadStaffPermission();
      }
    },
    loadStaffKycPermission() {
      this.$axios
        .get("superadmin/permission?for=staff-kyc", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data.data);
          this.permissions21 = res.data.data.data;
        });
    },
    staffkycpermission() {
      if (this.department_id != null && this.permissions.length > 0) {
        this.loadStaffKycPermission();
        this.loadPermissionDepartment();
      } else if (this.department_id != null && this.permissions.length == 0) {
        this.loadStaffKycPermission();
        this.loadPermissionDepartment();
      } else {
        this.loadStaffKycPermission();
      }
    },
    loadClientServicePermission() {
      this.$axios
        .get("superadmin/permission?for=clientservice", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data.data);
          this.permissions22 = res.data.data.data;
        });
    },
    clientservicepermission() {
      if (this.department_id != null && this.permissions.length > 0) {
        this.loadClientServicePermission();
        this.loadPermissionDepartment();
      } else if (this.department_id != null && this.permissions.length == 0) {
        this.loadClientServicePermission();
        this.loadPermissionDepartment();
      } else {
        this.loadClientServicePermission();
      }
    },
    loadClientServicechallanPermission() {
      this.$axios
        .get("superadmin/permission?for=clientservice-challan", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data.data);
          this.permissions23 = res.data.data.data;
        });
    },
    clientservicechallanpermission() {
      if (this.department_id != null && this.permissions.length > 0) {
        this.loadClientServicechallanPermission();
        this.loadPermissionDepartment();
      } else if (this.department_id != null && this.permissions.length == 0) {
        this.loadClientServicechallanPermission();
        this.loadPermissionDepartment();
      } else {
        this.loadClientServicechallanPermission();
      }
    },
    loadClientServicecommentPermission() {
      this.$axios
        .get("superadmin/permission?for=clientservice-comment", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data.data);
          this.permissions24 = res.data.data.data;
        });
    },
    clientservicecommentpermission() {
      if (this.department_id != null && this.permissions.length > 0) {
        this.loadClientServicecommentPermission();
        this.loadPermissionDepartment();
      } else if (this.department_id != null && this.permissions.length == 0) {
        this.loadClientServicecommentPermission();
        this.loadPermissionDepartment();
      } else {
        this.loadClientServicecommentPermission();
      }
    },
    loadClientServiceCertificatePermission() {
      this.$axios
        .get("superadmin/permission?for=clientservice-certificate", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data.data);
          this.permissions25 = res.data.data.data;
        });
    },
    clientservicecertificatepermission() {
      if (this.department_id != null && this.permissions.length > 0) {
        this.loadClientServiceCertificatePermission();
        this.loadPermissionDepartment();
      } else if (this.department_id != null && this.permissions.length == 0) {
        this.loadClientServiceCertificatePermission();
        this.loadPermissionDepartment();
      } else {
        this.loadClientServiceCertificatePermission();
      }
    },
    loadClientServiceDocumentPermission() {
      this.$axios
        .get("superadmin/permission?for=clientservice-document", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data.data);
          this.permissions26 = res.data.data.data;
        });
    },
    clientservicedocumentpermission() {
      if (this.department_id != null && this.permissions.length > 0) {
        this.loadClientServiceDocumentPermission();
        this.loadPermissionDepartment();
      } else if (this.department_id != null && this.permissions.length == 0) {
        this.loadClientServiceDocumentPermission();
        this.loadPermissionDepartment();
      } else {
        this.loadClientServiceDocumentPermission();
      }
    },
    loadClientServiceProfessionalPermission() {
      this.$axios
        .get("superadmin/permission?for=clientservice-professional", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data.data);
          this.permissions27 = res.data.data.data;
        });
    },
    clientserviceprofessionalpermission() {
      if (this.department_id != null && this.permissions.length > 0) {
        this.loadClientServiceProfessionalPermission();
        this.loadPermissionDepartment();
      } else if (this.department_id != null && this.permissions.length == 0) {
        this.loadClientServiceProfessionalPermission();
        this.loadPermissionDepartment();
      } else {
        this.loadClientServiceProfessionalPermission();
      }
    },
    loadClientServiceProfessionalStepPermission() {
      this.$axios
        .get("superadmin/permission?for=clientservice-professional-step", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data.data);
          this.permissions28 = res.data.data.data;
        });
    },
    clientserviceprofessionalsteppermission() {
      if (this.department_id != null && this.permissions.length > 0) {
        this.loadClientServiceProfessionalStepPermission();
        this.loadPermissionDepartment();
      } else if (this.department_id != null && this.permissions.length == 0) {
        this.loadClientServiceProfessionalStepPermission();
        this.loadPermissionDepartment();
      } else {
        this.loadClientServiceProfessionalStepPermission();
      }
    },
    loadClientServiceRequestDocPermission() {
      this.$axios
        .get("superadmin/permission?for=clientservice-request-doc", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data.data);
          this.permissions29 = res.data.data.data;
        });
    },
    clientservicerequestdocpermission() {
      if (this.department_id != null && this.permissions.length > 0) {
        this.loadClientServiceRequestDocPermission();
        this.loadPermissionDepartment();
      } else if (this.department_id != null && this.permissions.length == 0) {
        this.loadClientServiceRequestDocPermission();
        this.loadPermissionDepartment();
      } else {
        this.loadClientServiceRequestDocPermission();
      }
    },
    loadClientServiceTicketPermission() {
      this.$axios
        .get("superadmin/permission?for=clientservice-ticket", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data.data);
          this.permissions30 = res.data.data.data;
        });
    },
    clientserviceticketpermission() {
      if (this.department_id != null && this.permissions.length > 0) {
        this.loadClientServiceTicketPermission();
        this.loadPermissionDepartment();
      } else if (this.department_id != null && this.permissions.length == 0) {
        this.loadClientServiceTicketPermission();
        this.loadPermissionDepartment();
      } else {
        this.loadClientServiceTicketPermission();
      }
    },
    loadClientServiceTicketDetailPermission() {
      this.$axios
        .get("superadmin/permission?for=clientservice-ticket-detail", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data.data);
          this.permissions31 = res.data.data.data;
        });
    },
    clientserviceticketdetailpermission() {
      if (this.department_id != null && this.permissions.length > 0) {
        this.loadClientServiceTicketDetailPermission();
        this.loadPermissionDepartment();
      } else if (this.department_id != null && this.permissions.length == 0) {
        this.loadClientServiceTicketDetailPermission();
        this.loadPermissionDepartment();
      } else {
        this.loadClientServiceTicketDetailPermission();
      }
    },
    loadClientServiceTicketDocPermission() {
      this.$axios
        .get("superadmin/permission?for=clientservice-ticket-doc", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data.data);
          this.permissions32 = res.data.data.data;
        });
    },
    clientserviceticketdocpermission() {
      if (this.department_id != null && this.permissions.length > 0) {
        this.loadClientServiceTicketDocPermission();
        this.loadPermissionDepartment();
      } else if (this.department_id != null && this.permissions.length == 0) {
        this.loadClientServiceTicketDocPermission();
        this.loadPermissionDepartment();
      } else {
        this.loadClientServiceTicketDocPermission();
      }
    },
    loadClientServiceUpgradePermission() {
      this.$axios
        .get("superadmin/permission?for=clientservice-upgrade", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data.data);
          this.permissions33 = res.data.data.data;
        });
    },
    clientserviceupgradepermission() {
      if (this.department_id != null && this.permissions.length > 0) {
        this.loadClientServiceUpgradePermission();
        this.loadPermissionDepartment();
      } else if (this.department_id != null && this.permissions.length == 0) {
        this.loadClientServiceUpgradePermission();
        this.loadPermissionDepartment();
      } else {
        this.loadClientServiceUpgradePermission();
      }
    },
    loadClientServiceStatePermission() {
      this.$axios
        .get("superadmin/permission?for=state", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data.data);
          this.permissions34 = res.data.data.data;
        });
    },
    clientservicestatepermission() {
      if (this.department_id != null && this.permissions.length > 0) {
        this.loadClientServiceStatePermission();
        this.loadPermissionDepartment();
      } else if (this.department_id != null && this.permissions.length == 0) {
        this.loadClientServiceStatePermission();
        this.loadPermissionDepartment();
      } else {
        this.loadClientServiceStatePermission();
      }
    },
    loadPageTitle(){
      console.log('--------------------------');
        
        document.title =this.$store.state.superAdminPageTitles.permissions
    console.log(this.$store.state.superAdminPageTitles.permissions)
    console.log('--------------------------');
    }
  },
  created() {
    if (
      this.$route.meta.redirectIfLoggedIn == "true" &&
      localStorage.getItem("accessToken") == null
    ) {
      this.$router.push({ name: "Login" });
    } else {
      this.loadDepartment();
      this.loadPageTitle()
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

.custom-card {
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgba(206, 205, 205, 0.555);
  background-color: white;
}

.h4-heading {
  color: red;
  font-size: 1.2vw;
}

.font-heading {
  font-family: "Poppins", sans-serif;
  font-size: 3rem;
  color: rgb(216, 209, 209);
}

.text {
  font-family: "Poppins", sans-serif;
  color: #00364f;
}

.font {
  font-family: "Poppins", sans-serif;
}

.btns {
  background-color: #00364f;
  font-size: 12px;
  padding: 8px 8px 8px 8px;
}

.radius {
  border-radius: 23px;
}

.form-check-input:checked {
  background-color: #f21300;
  border-color: #f21300;
}

.tr-head {
  font-size: 11pt;
  margin-left: 25px;
  border-radius: 26px;
  background-color: #cdcdcd;
}

.table-scroll {
  overflow-y: auto;
}

.partisan-permission {
  border-bottom: 1px solid rgb(189, 188, 188);
}
</style>
